import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useAddContactPopupStore } from '@modules/AddContactModal/addContactStore';

import { CONTACT_TYPE } from '@modules/AddContactModal/data';

import AddRelatedContacts from '@modules/AddContactModal/components/FormBlocks/AddRelatedContacts';
import BasicInformation from '@modules/AddContactModal/components/FormBlocks/BasicInformation';
import ContactInformation from '@modules/AddContactModal/components/FormBlocks/ContactInformation';
import CreateMembers from '@modules/AddContactModal/components/FormBlocks/CreateMembers';
import LinkSpouseContact from '@modules/AddContactModal/components/FormBlocks/LinkSpouseContact';
import PersonalInformation from '@modules/AddContactModal/components/FormBlocks/PersonalInformation';
import PrimaryPoint from '@modules/AddContactModal/components/FormBlocks/PrimaryPoint';
import ProfileDetails from '@modules/AddContactModal/components/FormBlocks/ProfileDetails';
import Tags from '@modules/AddContactModal/components/FormBlocks/TagsBlock';

function FormConstructor() {
  const store = useAddContactPopupStore();

  const { control, formState, setValue } = useFormContext(); //delete after refactor Add Contact popup

  //delete after refactor Add Contact popup
  const properties = { control, formState, options: store.options, setValue };

  if (store.selectedType === CONTACT_TYPE.Individual) {
    switch (store.selectedStep) {
    case 1:
      return (
        <>
          <PersonalInformation properties={ properties } />
          <ProfileDetails
            header='Profile Details'
            properties={ properties }
          />
        </>
      );
    case 2:
      return (
        <ContactInformation
          properties={ properties }
          type={ store.selectedType }
        />
      );
    case 3:
      return (
        <>
          <LinkSpouseContact/>
          <AddRelatedContacts/>
        </>
      );
    case 4:
      return (
        <Tags isInterestTags={ true } store={ store } />
      );
    default:
      break;
    }
  }

  if (store.selectedType === CONTACT_TYPE.Household) {
    switch (store.selectedStep) {
    case 1:
      return (
        <>
          <CreateMembers properties={ properties } />
          <ProfileDetails
            header='Household Details'
            isHouseHold={ true }
            properties={ properties }
          />
        </>
      );
    case 2:
      return (
        <ContactInformation
          properties={ properties }
          type={ store.selectedType }
        />
      );
    case 3:
      return (
        <AddRelatedContacts store={ store } />
      );
    case 4:
      return (
        <Tags store={ store } />
      );
    default:
      break;
    }
  }
  if (store.selectedType === CONTACT_TYPE.Business) {
    switch (store.selectedStep) {
    case 1:
      return (
        <>
          <BasicInformation properties={ properties } />
          <ProfileDetails
            header='Profile Details'
            properties={ properties }
            type={ store.selectedType }
          />
        </>
      );
    case 2:
      return (
        <ContactInformation
          properties={ properties }
          type={ store.selectedType }
        />
      );
    case 3:
      return (
        <PrimaryPoint/>
      );
    case 4:
      return (
        <Tags store={ store } />
      );
    default:
      break;
    }
  }
}

export default observer(FormConstructor);
