import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Navigate } from 'react-big-calendar';

import { useStore } from '@store';
import { MODAL_TYPE } from '@constants/modalTypes';
import { CALENDAR_VIEWS, CALENDAR_VIEW_OPTIONS } from '@constants/common';

import { ActivityPopup } from '@/modules/ActivityPopup';
import AddActivity from '@components/Icons/AddActivity';
import BlockButton from '@components/BlockButton';
import ContentHeader from '@components/BasicLayoutComponents/ContentHeader';
import CustomSelect from '@components/CustomSelect';
import FilterButton from '@components/FilterButton';

import {
  getTimeDataBasedOnView,
  convertSecondStringToMinutes
} from '@/modules/ActivityPopup/utils';

import {
  LabelWrapper,
  NavigateWrapper,
  StyledIconButton,
  ViewWrapper
} from './styles';

export const Toolbar = observer(({
  label,
  onNavigate,
  view
}) => {
  const modalStore = useStore().ModalStore;
  const calendarStore = useStore().CalendarStore;
  const { settings: { activityDefaultTime } } = useStore().SettingsStore;
  const {
    currentView,
    deleteActivity,
    isFiltersOpen,
    onClear,
    saveOrUpdateActivity,
    selectedFiltersCount,
    toggleFiltersIsOpen,
  } = calendarStore;

  const onTodayClick = useCallback(() => onNavigate(Navigate.TODAY), []);
  const onPrevClick = useCallback(() => onNavigate(Navigate.PREVIOUS), []);
  const onNextClick = useCallback(() => onNavigate(Navigate.NEXT), []);
  const onViewChange = useCallback(({ target: value }) => {
    calendarStore.setCurrentView(value.value);
  }, []);

  const showAddActivity = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CALENDAR_ACTIVITY,
      modalProps: {
        initData: getTimeDataBasedOnView({
          startDateObj: new Date(),
          duration: convertSecondStringToMinutes(activityDefaultTime) // TODO convert it at normalizer
        }),
        onConfirm: saveOrUpdateActivity,
        onDelete: deleteActivity
      },
      component: ActivityPopup
    });
  },[activityDefaultTime, calendarStore.deleteActivity, calendarStore.saveOrUpdateActivity, modalStore]);

  return (
    <ContentHeader
      style={ {
        position: 'relative',
      } }
    >
      <ViewWrapper>
        <CustomSelect
          onChange={ onViewChange }
          options={ CALENDAR_VIEW_OPTIONS }
          value={ currentView }
        />
      </ViewWrapper>

      <BlockButton
        onClick={ onTodayClick }
        style={ {
          marginLeft: '12px',
          minWidth: 'auto'
        } }
      >
        Today
      </BlockButton>

      <NavigateWrapper $isWide={ view === CALENDAR_VIEWS.week || view === CALENDAR_VIEWS.day }>
        <StyledIconButton
          onClick={ onPrevClick }
          size={ 'medium' }
        >
          <ChevronLeft  size={ 16 }/>
        </StyledIconButton>

        <LabelWrapper>
          {label}
        </LabelWrapper>

        <StyledIconButton
          onClick={ onNextClick }
          size={ 'medium' }
        >
          <ChevronRight  size={ 16 }/>
        </StyledIconButton>
      </NavigateWrapper>

      <BlockButton
        onClick={ showAddActivity }
        startIcon={ <AddActivity /> }
        style={ {
          marginLeft: 'auto',
          marginRight: '10px'
        } }
      >
        Add Activity
      </BlockButton>
      <FilterButton
        badgeClickHandler={ onClear }
        badgeCount={ selectedFiltersCount }
        buttonCLickHandler={ toggleFiltersIsOpen }
        enableFilterMode={ !isFiltersOpen }
      />
    </ContentHeader>
  );
});
