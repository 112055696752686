import { COLORS } from '@constants/colors';

export const getDeleteStatusColor = (leftDays: number, maxNumberOfDaysBeforeDelete: number) => {
  if(leftDays <= 3) {
    return COLORS.red;
  }

  if(leftDays >= 4 && leftDays <= 9) {
    return COLORS.yellow;
  }
  
  if(leftDays >= 10 && leftDays <= maxNumberOfDaysBeforeDelete) {
    return COLORS.green;
  }
};
