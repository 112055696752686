import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';

import { ROUTES } from '@constants/routes';

import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import Table from '@components/Table';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import { ActionButtons, TrashRow, DateFilters, UsersFilter } from './components';

import {
  ACCOUNTS_AND_PLANS_COLUMNS,
  ACTIVITIES_COLUMNS,
  CLIENT_OPPORTUNITIES_COLUMNS,
  CONTACTS_COLUMNS,
  LISTS_COLUMNS,
  SALES_CYCLES_COLUMNS,
  TASKS_COLUMNS,
} from './data';

import { TrashTableProps } from './types';
import { TrashTabValues } from '@pages/Trash/types';
import { NormalizerItem } from '@/shared/services/store/trashStore/types';

import { ButtonsWrapper } from './styles';


export const TrashTable = observer(({
  onDeleteHandler,
  onRestoreHandler
}: TrashTableProps) => {
  const trashStore = useStore().TrashStore;
  const {
    currentTrashView,
    isPageLoads,
    table,
    trashStorageDays,
  } = trashStore;

  const match = useRouteMatch();

  const currentViewColumns = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (match.url) {
    case ROUTES.trashContacts:
      trashStore.setView(TrashTabValues.Contacts);
      return CONTACTS_COLUMNS;
    case ROUTES.trashLists:
      trashStore.setView(TrashTabValues.Lists);
      return LISTS_COLUMNS;
    case ROUTES.trashActivities:
      trashStore.setView(TrashTabValues.Activities);
      return ACTIVITIES_COLUMNS;
    case ROUTES.trashTodos:
      trashStore.setView(TrashTabValues.Tasks);
      return TASKS_COLUMNS;
    case ROUTES.trashSalesCycles:
      trashStore.setView(TrashTabValues.SalesCycles);
      return SALES_CYCLES_COLUMNS;
    case ROUTES.trashClientOpportunities:
      trashStore.setView(TrashTabValues.ClientOpportunities);
      return CLIENT_OPPORTUNITIES_COLUMNS;
    case ROUTES.trashAccountsAndPlans:
      trashStore.setView(TrashTabValues.AccountsAndPlans);
      return ACCOUNTS_AND_PLANS_COLUMNS;
    default: {
      const unexpected = match.url;
      throw new TypeError(`Unexpected status: ${unexpected}`);
    }
    }
  }, [ match, trashStore ]);

  return (
    <>
      <PreTableWrapper>
        <UsersFilter/>
        <DateFilters/>
        {
          table.selectedIDs.length > 0 &&
          <ButtonsWrapper>
            <ActionButtons
              onPermanentlyDeleteClick={ onDeleteHandler }
              onRestoreClick={ onRestoreHandler }
              selectedItemsCount={ table.selectedIDs.length }
            />
          </ButtonsWrapper>
        }
      </PreTableWrapper>
      <Table>
        <Table.Container triggerScrollToTop={ table.currentPage }>
          <Table.Header
            columns={ currentViewColumns }
            globalCheckboxProps={ {
              checked: table.globalCheckboxState.checked,
              indeterminate: table.globalCheckboxState.indeterminate,
              onChange: table.onGlobalCheckboxClick,
            } }
            onSortChangeCb={ table.setMultipleSorting }
            sortingState={ table.multipleSorting }
            withGlobalCheckbox
          />
          <Table.Body>
            {
              table.items.map((dataItem: NormalizerItem) => (
                <TrashRow
                  currentTrashView={ currentTrashView }
                  dataItem={ dataItem }
                  isCheckboxActive={ table.getItemCheckboxState(dataItem) }
                  key={ `${dataItem.item.id}${(Math.random() * 1000000)}` }
                  onCheckboxClickCb={ table.onCheckBoxClick }
                  onPermanentlyDeleteClick={ onDeleteHandler }
                  onRestoreClick={ onRestoreHandler }
                  trashStorageDays={ trashStorageDays }
                />
              ))
            }
          </Table.Body>
          {
            !isPageLoads && table.items.length === 0 &&
            <ContentCenter>
              <NoDataItemsView/>
            </ContentCenter>
          }
        </Table.Container>
        <Table.Footer
          currentPage={ table.currentPage }
          onPageChangeCb={ table.setCurrentPage }
          pagesCount={ table.totalPages }
        >
          <Table.FooterCountInfo
            from={ table.itemsFrom }
            to={ table.itemsTo }
            total={ table.totalItems }
          />
        </Table.Footer>
      </Table>
    </>
  );
});
