import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@services/store/store';

import { FILTER_ALL_BTN_LABEL } from '@constants/common';

import { Item } from '@components/RowFilter/types';
import { PreTablePanelWrapperProps } from './types';
import { AccountAndPlansRowPreTablePanel } from '@/modules/AccountAndPlansRowPreTablePanel';
import { IdType } from '@/shared/types/commonTypes';
import { ACCOUNTS_AND_PLANS_FILTER_NAMES } from '@/shared/constants/accountsAndPlanData';

export const PreTablePanelWrapper = observer(({
  onAddAccountOrPlan,
  onDeleteAccountAndPlan,
}: PreTablePanelWrapperProps) => {

  const contactDetailsAccountAndPlanStore = useStore().ContactDetailsAccountAndPlanStore;
  const { table, filters, filtersData } = contactDetailsAccountAndPlanStore;
  const { isLoad } = useStore().ContactDetailsStore;

  const onTypeFilterChange = useCallback((value: Item) => {
    const newValue = value === FILTER_ALL_BTN_LABEL
      ? null
      : {
        [ACCOUNTS_AND_PLANS_FILTER_NAMES.accountType]: value.toString()
      };
    contactDetailsAccountAndPlanStore.setTypeFilter(newValue);
  }, [ contactDetailsAccountAndPlanStore ]);

  const onUserFilterChange = useCallback((value: Array<IdType>) => {
    contactDetailsAccountAndPlanStore.setFilters({
      [ACCOUNTS_AND_PLANS_FILTER_NAMES.users]: value
    });
  }, [ contactDetailsAccountAndPlanStore ]);

  return (
    <AccountAndPlansRowPreTablePanel
      filters={ filters }
      filtersData={ filtersData }
      isLoad={ isLoad }
      onAddClick={ onAddAccountOrPlan }
      onDeleteClick={ onDeleteAccountAndPlan }
      onTypeFilterChange={ onTypeFilterChange }
      onUserFilterChange={ onUserFilterChange }
      table={ table }
    />
  );
});