import { action, flow, makeAutoObservable } from 'mobx';
import { pick } from 'lodash';
// @ts-ignore
import { v4 } from 'uuid';

import { Store } from '@store';

import { addToContactOldTag, createTag, deleteContactTag,  } from '@services/api/tags/tags';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { convertArrayOfStringToHtmlLines } from '@/shared/utils/NotificationHelper/generateMessage';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';

import { ENTITY_NAMES, TAGS_FRONT_ID_KEY } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import {
  TagListItem,
  TagSaveUpdateResponse,
  TagTypeWithoutOpportunities
} from '@/shared/types/tags';
import { IdType } from '@/shared/types/commonTypes';
import { SaveNewTagResponse } from '@/shared/types/contactOverview';

const LENGTH_OF_VISIBLE_TAGS = 4;

export default class ContactTagStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  coreStore: Store;
  currentContactId: IdType | null = null;
  editIndex: number | null = null;
  errorMessage: string | null = null;
  globalTags: Array<TagListItem> | null = null;
  isFetching: boolean = false;
  tags: Array<TagListItem> = [];
  type:TagTypeWithoutOpportunities;

  constructor(coreStore: Store, type: TagTypeWithoutOpportunities) {
    makeAutoObservable(this, {
      onAdd: action.bound,
      onDelete: flow.bound,
      onSaveOldTag: flow.bound,
      onEditEnd: action.bound,
      onEditStart: action.bound,
      onSave: flow.bound,
      setGlobalTag: action.bound,
      getFilteredGlobalTags: action.bound,
      setErrorMessage: action.bound,
      tags: true,
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.tag
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.type = type;
  }

  setGlobalTag(tags: Array<TagListItem>) {
    this.globalTags = tags;
  }

  getFilteredGlobalTags() {
    if(this.globalTags) {
      return this.tags ? this.globalTags.filter(globalTag => {
        return !this.tags.some(contactTag => globalTag.id === contactTag.id);
      }) : this.globalTags;
    }
    return [];
  }

  onAdd(){
    const newTag = {
      type: this.type,
      id: null,
      label: 'New Tag',
      [TAGS_FRONT_ID_KEY]: v4()
    };

    if(this.tags.length >= LENGTH_OF_VISIBLE_TAGS) {
      this.tags.splice(LENGTH_OF_VISIBLE_TAGS - 1, 0, newTag);
      this.editIndex = LENGTH_OF_VISIBLE_TAGS - 1;
    } else {
      this.tags = [
        ...this.tags,
        newTag
      ];
      this.editIndex = this.tags.length > 0 ? this.tags.length - 1 : this.tags.length;
    }
  }

  *onDelete(item: TagListItem) {
    this.setIsFetching(true);
    try {
      if(item && typeof item.id === 'number') {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => deleteContactTag({ 'ids': [ item.contactTagId ] }),
          onError: () => this.notificationHelper.delete({
            status: NOTIFICATION_TYPES.error,
          }),
          onSuccess: () => this.notificationHelper.delete({
            status: NOTIFICATION_TYPES.success,
          }),
        });
        this.tags = this.tags.filter(tagItem => tagItem.id && tagItem.id !== item.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      this.setIsFetching(false);
    }
  }

  onEditEnd() {
    if(this.editIndex !== null) {
      this.tags.splice(this.editIndex, 1);
    }
    this.editIndex = null;
    this.errorMessage = null;
  }

  onEditStart(item: TagListItem){
    if(TAGS_FRONT_ID_KEY in item){
      return;
    }

    this.editIndex = this.tags.findIndex(tag => tag.id && tag.id === item.id);
  }

  *onSaveOldTag(arrayOfTags: Array<TagListItem>, saveEnd: () => void) {
    this.setIsFetching(true);
    try {
      const params = arrayOfTags.map(tag => ({
        contactId: this.currentContactId!,
        tagId: tag.id,
      }));
      
      const countOfEntities = arrayOfTags.length;

      const start = async () => {
        await addToContactOldTag(params);
        this.setTags([...this.tags, ...arrayOfTags]);
        saveEnd();
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.create({
          status: NOTIFICATION_TYPES.error,
        }),
        onSuccess: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.success,
          message: convertArrayOfStringToHtmlLines([
            `The ${ENTITY_NAMES.tag}${countOfEntities > 1 ? '(s)' : ''} has been added.`
          ]),
          customAction: 'addToContactOldTag',
        }),
      });
    } catch (error) {
      const errorObject = error as TagSaveUpdateResponse;
      const errorMessage = errorObject.response?.data?.message[0];

      if(errorMessage){
        this.setErrorMessage(errorMessage);
      }
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      this.setIsFetching(false);
    }
  }

  *onSave(item: TagListItem, saveEnd: () => void) {
    if(this.errorMessage){
      this.setErrorMessage(null);
    }

    const itemIndex = this.editIndex as number;

    this.setIsFetching(true);
    try {
      const start = async () => {
        const response: SaveNewTagResponse = await createTag({
          ...pick(item, ['type', 'label']),
          contactId: this.currentContactId!
        });
        this.tags[itemIndex] = response.data.data;
        this.editIndex = null;
        saveEnd();
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.create({
          status: NOTIFICATION_TYPES.error,
        }),
        onSuccess: () => this.notificationHelper.create({
          status: NOTIFICATION_TYPES.success,
        }),
        continueOnError: false,
      });
    } catch (error) {
      const errorObject = error as TagSaveUpdateResponse;
      const errorMessage = errorObject.response?.data?.message[0];

      if(errorMessage){
        this.setErrorMessage(errorMessage);
      }
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      this.setIsFetching(false);
    }
  }

  resetState() {
    this.editIndex = null;
    this.setErrorMessage(null);
    this.setGlobalTag([]);
    this.setTags([]);
  }

  setErrorMessage(message: string | null) {
    this.errorMessage = message;
  }

  setIsFetching(state: boolean) {
    this.coreStore.ContactDetailsStore.toggleLoadState(state);
  }

  setTags(tags: Array<TagListItem>){
    this.tags = tags;
  }

  setCurrentContactId(id: IdType) {
    this.currentContactId = id;
  }
}