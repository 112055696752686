import { STEP_STATES } from './data';

import { StepStates } from './types';

export const getStepState = (activeStep: number, index: number): StepStates => {
  if(index < activeStep) {
    return STEP_STATES.completed;
  }
  if(index === activeStep) {
    return STEP_STATES.active;
  }
  return STEP_STATES.disabled;
};
