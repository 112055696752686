import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import ContentCenter from '@components/ContentCenter';
import DeleteContactsPopup from '@modules/DeleteContactsPopup';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table from '@components/Table';

import { ListContactItem } from '@/shared/types/lists';

import { ListContactsTableRow } from './components';

import { COLUMNS } from '@constants/contactsTableColumnsConfig';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { LISTS_TYPES } from '@constants/lists';
import { MODAL_TYPE } from '@constants/modalTypes';

import { ListContactsTableProps } from './types';

export const ListContactsTable = observer(({
  listType,
  onRemove,
  onAddContactClick,
}: ListContactsTableProps) => {
  const listContactsStore = useStore().ListsStore.ListContactsStore;
  const { trashStorageDays } = useStore().TrashStore;
  const routerStore = useStore().RouterStore;
  const modalStore = useStore().ModalStore;

  const {
    table,
    isContactManagement,
    isLoad,
    isPageActive,
  } = listContactsStore;

  const moveToContactOverview = useCallback((id: ListContactItem['id'], type: ListContactItem['type']) => {
    routerStore.moveToContact({
      id,
      contactType: type,
      tab: CONTACT_DETAILS_TAB.overview
    });
  }, [routerStore]);

  const showLimitedAccessPopup = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const onDeleteContact = useCallback((id: ListContactItem['id']) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_CONTACTS_POPUP,
      modalProps: {
        arrayOfIds: [id],
        numberOfDaysUntilDeletion: trashStorageDays,
        onSave: listContactsStore.deleteContacts.bind(listContactsStore)
      },
      component: DeleteContactsPopup,
    });
  }, [listContactsStore, modalStore]);

  const onRatingChange = useCallback((id: string | number, newRating: number) => {
    listContactsStore.updateRating(id, newRating);
  }, [listContactsStore]);

  const onBookmarkChange = useCallback((id: string | number,) => {
    listContactsStore.updateBookmark(id);
  }, [listContactsStore]);

  return (
    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: table.globalCheckboxState.checked,
            indeterminate: table.globalCheckboxState.indeterminate,
            onChange: table.onGlobalCheckboxClick,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isLoad && table.items.length > 0 &&
            table.items.map(item => (
              <ListContactsTableRow
                data={ item }
                isCheckboxActive={ table.getItemCheckboxState(item) }
                isContactManagement={ isContactManagement }
                key={ item.id }
                listType={ listType }
                moveToContactOverview={ moveToContactOverview }
                onCheckboxClick={ table.onCheckBoxClick }
                onDelete={ onDeleteContact }
                onLimitedClick={ showLimitedAccessPopup }
                onRatingChangeCb={ onRatingChange }
                onRemove={ onRemove }
                onRowBookmarkCb={ onBookmarkChange }
              />
            ))
          }
        </Table.Body>
        {
          isPageActive && !isLoad && table.items.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              addActionButtonClickHandler={ onAddContactClick }
              addActionButtonLabel={ 'Add Contacts to List' }
              withAction={ listType === LISTS_TYPES.Manual }
            />
          </ContentCenter>
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>
  );
});
