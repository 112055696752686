import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import { StyledTextFieldProps } from '@components/CustomTextField/types';

import { getInputsErrorStyles } from '@/shared/utils/getInputsErrorStyles';

import { COLORS } from '@constants/colors';

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  position: relative;
  width: 100%;
  
  ${({ $showError }) => getInputsErrorStyles(Boolean($showError))};

  /* to reset browser styles for autofill options */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${COLORS.white} inset !important;
  }
`;
