import React from 'react';

import { PreHeader, SideWrapper } from './components';

import {
  ContentWrapper,
  ContentWithoutHeaderWrapper,
  FluidWrapper,
  MainWrapper,
  TabWrapper,
  Wrapper,
} from './styles';

import { SurfaceProps } from './types';


export const Surface = ({ children } : SurfaceProps) => <Wrapper>{children}</Wrapper>;

Surface.Content = ContentWrapper;
Surface.ContentWithoutHeader = ContentWithoutHeaderWrapper;
Surface.Main = MainWrapper;
Surface.Side = SideWrapper;
Surface.MainFluid = FluidWrapper;
Surface.PreHeader = PreHeader;
Surface.TabWrapper = TabWrapper;
