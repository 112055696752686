import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { differenceInDays, format, parse } from 'date-fns';

import { getDeleteStatusColor } from './utils';
import { pluralize } from '@/shared/utils/pluralize';

import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { DeleteStatusText } from './styles';

import { CommonCellsProps } from './types';
import { DotsMenuItemTypes, GetDotsMenuOptionProps } from '@components/DotsMenu/types';


export const CommonCells = observer(({
  isAbleRole,
  dataItem,
  onRestoreClick,
  onPermanentlyDeleteClick,
  trashStorageDays
}: CommonCellsProps) => {
  
  const {
    deleteAt,
    deleteBy
  } = dataItem;

  const deleteDateData = useMemo(() => {
    const now = new Date();
    const parsedDate = parse(deleteAt, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, now);
    const date = format(parsedDate, 'MMMM d, yyyy');
    const time = format(parsedDate, 'H:mm aa');

    const daysHavePassed = differenceInDays(now, parsedDate);
    const daysLeftToDelete = trashStorageDays - daysHavePassed;

    return {
      deleteDateText: `${ date } at ${ time }`,
      deleteDaysNumberText: pluralize(daysLeftToDelete, 'day', 's'),
      deleteStatusColor: getDeleteStatusColor(daysLeftToDelete, trashStorageDays),
    };
  }, [ deleteAt ]);

  const menuOptions = useMemo(() => {
    const menuDotsConfig: GetDotsMenuOptionProps = {
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.restore ],
    };
    if (isAbleRole) {
      menuDotsConfig.arrayOfTypes.push(DOTS_MENU_ITEM_TYPES.permanentDelete);
      menuDotsConfig.withTopSeparators = [ DOTS_MENU_ITEM_TYPES.permanentDelete ];
    }
    return getDotsMenuOptions(menuDotsConfig);
  }, [ isAbleRole ]);

  const onMenuSelect = useCallback((action: DotsMenuItemTypes) => {
    if (action === DOTS_MENU_ITEM_TYPES.restore) {
      onRestoreClick([ dataItem?.item.id ]);
    }

    if (action === DOTS_MENU_ITEM_TYPES.permanentDelete) {
      onPermanentlyDeleteClick([ dataItem?.item.id ]);
    }
  }, [ dataItem, onPermanentlyDeleteClick, onRestoreClick ]);

  return (
    <>
      <Table.Cell>
        <TextWithTooltip text={ `by ${ deleteBy }` } />
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ deleteDateData.deleteDateText } />
      </Table.Cell>
      <Table.Cell>
        <DeleteStatusText $color={ deleteDateData.deleteStatusColor }>
          { deleteDateData.deleteDaysNumberText }
        </DeleteStatusText>
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '10px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </>
  );
});
