import styled from 'styled-components';

export const Wrapper = styled.div``;
export const StepperWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100px;
  padding: 0 70px 40px 40px;
  box-sizing: border-box;
`;
