import React, { useCallback } from 'react';

import { observer } from 'mobx-react';

import { Trash, Wallet } from '@/assets';

import { ACCOUNTS_AND_PLANS_TYPES, ACCOUNTS_AND_PLANS_FILTER_NAMES } from '@constants/accountsAndPlanData';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';

import { UserSelectFilter } from '@modules/UserSelectFilter';
import BlockButton from '@components/BlockButton';
import BubbleButton from '@components/BubbleButton';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import RowFilter from '@components/RowFilter';

import { ButtonsWrapper, RemoveButtonWrapper, TypeFilterWrapper } from './styles';

import { AccountAndPlansRowPreTablePanelProps } from './types';

export const AccountAndPlansRowPreTablePanel = observer(({
  filters,
  filtersData,
  isLoad,
  onAddClick,
  onDeleteClick,
  onTypeFilterChange,
  onUserFilterChange,
  table
}: AccountAndPlansRowPreTablePanelProps) => {

  const onUserFilterChangeWrapper = useCallback((value: Array<string>) => {
    onUserFilterChange(value);
  }, [ onUserFilterChange ]);

  const onDeleteClickWrapper = useCallback(() => onDeleteClick(table.selectedIDs), [onDeleteClick, table.selectedIDs]);

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters
      } }
    >
      <UserSelectFilter
        closeTrigger={ isLoad }
        onChange={ onUserFilterChangeWrapper }
        usersOptions={ filtersData?.[ACCOUNTS_AND_PLANS_FILTER_NAMES.users] }
        value={ filters?.[ACCOUNTS_AND_PLANS_FILTER_NAMES.users] || [] }
        withAllUserOption={ true }
      />
      <TypeFilterWrapper>
        <RowFilter
          allButtonLabel={ FILTER_ALL_BTN_LABEL }
          items={ Object.values(ACCOUNTS_AND_PLANS_TYPES) }
          limit={ 3 }
          onChange={ onTypeFilterChange }
          renderItem={ ({
            content,
            isSelected,
            isShowMore,
            onClick,
            isSelectedFromMore,
            indexForKey
          }) => (
            <BubbleButton
              $isActive={ isSelected && !isShowMore }
              $isSecondActiveStyle={ (isSelected && isShowMore) || isSelectedFromMore }
              key={ indexForKey }
              onClick={ onClick }
            >
              { content }
            </BubbleButton>
          ) }
        />
      </TypeFilterWrapper>
      <ButtonsWrapper>
        {
          table.selectedIDs.length > 0 &&
          <RemoveButtonWrapper>
            <BlockButton
              onClick={ onDeleteClickWrapper }
              startIcon={ <Trash/> }
            >
              Delete All Selected
            </BlockButton>
          </RemoveButtonWrapper>
        }
        <BlockButton
          onClick={ onAddClick }
          startIcon={ <Wallet/> }
        >
          New Account or Plan
        </BlockButton>
      </ButtonsWrapper>
    </PreTableWrapper>
  );
});
