import { action, flow, makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';

import { getAllTags, getTags } from '@services/api/tags/tags';
import { updateTodo, saveTodo, deleteTodo } from '@services/api/addAndEditTask/addAndEditTask';
import { saveActivity } from '@services/api/addAndEditActivity/addAndEditActivity';
import {
  getActivityForOverview,
  getContactOverView,
  updateContactFlags,
  updateContactRating
} from '@services/api/contacts/contacts';
import { deleteNote, getNoteById, saveNote } from '@services/api/notes/notes';

import { NotesUtilsStore } from '@modules/NotesAndHistory';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { Store } from '@store';
import TagStore from './contactTagStore';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { contactNormalizer, getParamsToSaveContactNote, normalizerActivityData, spouseNormalizer } from './utils';
import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { noteConverter, notesNormalized } from '@/shared/utils/contactNoteNormalizer';
import { tagNormalizer } from '@/shared/utils/toDosNormalizers';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';
import { CONTACT_TYPES, ENTITY_NAMES, TAGS_TYPES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import {
  ActivityData,
  ClientData,
  GetContactOverView,
  OverviewContact,
  RelatedContact,
  UseFormMethods
} from '@/shared/types/contactOverview';
import { BackendTodoFormFields, DeleteTodoParams, TodoItem } from '@/shared/types/todos';
import { BalanceData } from '@modules/ContactComponents/AccountAndPlans/types';
import { IdType, ItemWithId } from '@/shared/types/commonTypes';
import { ContactActivity } from '../contactDetailsActivitiesStore/types';
import { ContactNote } from '@/shared/types/note';
import { ContactsData } from '@/shared/types/contactsData';
import { ConvertActivityData } from '@/shared/types/activityPopup';
import { NoteTagItem } from '@/shared/types/tags';
import { TodoPopupTodoNoteTags } from '@modules/TodoPopup/store/types';


export class ContactDetailsOverview {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  general: TagStore;
  interest: TagStore;

  accountPlansData: Array<BalanceData> | null = null;
  adviserData: ClientData | null = null;
  contact: OverviewContact | null = null;
  contactsDetails: ContactsData | null = null;
  contactSpouse: RelatedContact | null = null;
  coreStore: Store;
  editIndex: number | null = null;
  errorMessage: string | null = null;
  id: IdType | null = null;
  nextCalendarActivityData: Array<ContactActivity> | null = null;
  notes: Array<ContactNote> | null = null;
  notesUtilsStore: NotesUtilsStore;
  pendingTaskData: Array<TodoItem> | null = null;
  predefinedTags: Array<NoteTagItem> = [];
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      asyncRequestExecutor: false,
      deleteNote: flow.bound,
      getContactOverView: flow.bound,
      getMoreActivity: flow.bound,
      getGlobalTags: flow.bound,
      getNote: flow.bound,
      init: action.bound,
      removeTodo: flow.bound,
      resetStore: action.bound,
      saveActivity: flow.bound,
      saveNote: flow.bound,
      saveTodo: flow.bound,
      updateFlags: flow.bound,
      updateRating: flow.bound,
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.contact
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.interest = new TagStore(this.coreStore, TAGS_TYPES.interest);
    this.general = new TagStore(this.coreStore, TAGS_TYPES.general);  

    this.notesUtilsStore = new NotesUtilsStore();
  }
  
  init(id: IdType, methods: UseFormMethods) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    this.id = id;
    this.useFormMethods = methods;
    this.getContactOverView();
    this.getTodoNoteTags();
  }

  async *getContactOverView() {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      const response: AxiosResponse<GetContactOverView> = yield getContactOverView({ id: this.id });
      const data = response.data.data['0'];
      
      yield contactDetailsStore.setCurrentContact(data);

      if(data.type !== CONTACT_TYPES.HOUSEHOLD) {
        this.contactsDetails = data.contacts;
      } else {
        this.contactsDetails = data.householderContacts.filter(contact => contact.householdOwner === 'Yes')[0].contacts;
      }

      this.contact = contactNormalizer(data);
      this.notesUtilsStore.setCurrentLinkedContact(this.contact);
      this.contactSpouse = spouseNormalizer(data);

      this.setBookmarkState(data.flagged);
      this.setRating(data.rating);

      this.adviserData = {
        primaryAdviser: data.officePrimaryAdviserData,
        secondaryAdviser: data.officeSecondaryAdviserData,
      };
      this.nextCalendarActivityData = normalizerActivityData([
        ...data.nextCalendarActivityData.currentActivities,
        ...data.nextCalendarActivityData.nextActivities
      ]);
      this.pendingTaskData = data.pendingTaskData;
      this.accountPlansData = Object.values(data.accountPlansData);

      yield this.general.setCurrentContactId(this.contact!.id);
      yield this.general.setTags(data.contactTags.General);

      yield this.interest.setCurrentContactId(this.contact!.id);
      yield this.interest.setTags(data.contactTags.Interest);

      this.getGlobalTags();

      this.notes = notesNormalized(data.contactNotesData, this.contact);
      if(this.useFormMethods) {
        this.useFormMethods.reset({
          [CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.notes]: this.notes.length > 0 ? this.notes : []
        });
      }

      this.coreStore.AppHeaderStore.setLastViewedContactsList(data.lastFiveContacts);
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *getGlobalTags() {
    try {
      const tagsResponse: AxiosResponse<any> = yield getAllTags();

      this.general.setGlobalTag(tagsResponse.data.data.General);
      this.interest.setGlobalTag(tagsResponse.data.data.Interest);
    } catch (error) {
      console.log(error);
    }
  }

  setBookmarkState(state: number) {
    if(this.contact) {
      this.contact.flagged = state;
    }
  }

  setRating(rating: number) {
    if(this.contact) {
      this.contact.rating = rating;
    }
  }

  *updateFlags(id: ItemWithId, state: boolean){
    try {
      const params = getDataForBookmark([id], state);
      yield updateContactFlags(params);

      this.setBookmarkState(Number(state));
    } catch (error) {
      console.log(error);
    }
  }

  *saveTodo(data: BackendTodoFormFields) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      if(data.id) {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateTodo(data),
          onError: () => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.error,
            otherEntityName: ENTITY_NAMES.task
          }),
          onSuccess: () => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.success,
            otherEntityName: ENTITY_NAMES.task
          }),
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveTodo(data),
          onError: () => this.notificationHelper.create({
            status: NOTIFICATION_TYPES.error,
            otherEntityName: ENTITY_NAMES.task
          }),
          onSuccess: () => this.notificationHelper.create({
            status: NOTIFICATION_TYPES.success,
            otherEntityName: ENTITY_NAMES.task
          }),
        });
      }
      yield this.getContactOverView();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *removeTodo(params: DeleteTodoParams) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteTodo(params),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          otherEntityName: ENTITY_NAMES.task,
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          otherEntityName: ENTITY_NAMES.task,
        })
      });
      yield this.getContactOverView();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTodoNoteTags() {
    try {
      const tagsResp: TodoPopupTodoNoteTags = yield getTags({
        type: 'Contact Note',
        searchPhrase: ''
      });

      this.predefinedTags = tagNormalizer(tagsResp.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  *getMoreActivity() {
    try {
      const startDate = this.nextCalendarActivityData?.[this.nextCalendarActivityData?.length - 1].startDate;
      
      const tagsResp: ActivityData = yield getActivityForOverview({
        primaryContactId: this.contact!.id,
        startDate
      });
      const restActivities = normalizerActivityData(tagsResp.data.data.nextActivities);
      this.nextCalendarActivityData?.push(...restActivities);
    } catch (error) {
      console.log(error);
    }
  }
  
  *saveNote(note: ContactNote) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      const params = getParamsToSaveContactNote(this.contact!.id, note);
      yield saveNote(params);
      yield this.getContactOverView();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *deleteNote(id: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      yield deleteNote({ ids: [id] });
      yield this.getContactOverView();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *getNote(id: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      const response: AxiosResponse<any> = yield getNoteById({ id });

      const respNote = noteConverter([response.data.data], this.contact!)[0];

      this.notes = this.notes!.map((note) => note.id === respNote.id ? respNote : note);
      this.useFormMethods.reset({ [CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.notes]: this.notes });
      
      return respNote;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *saveActivity(data: ConvertActivityData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveActivity(data),
        onError: () => this.notificationHelper.create({
          status: NOTIFICATION_TYPES.error,
          otherEntityName: ENTITY_NAMES.activity,
        }),
        onSuccess: () => this.notificationHelper.create({
          status: NOTIFICATION_TYPES.success,
          otherEntityName: ENTITY_NAMES.activity,
        })
      });
      yield this.getContactOverView();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateRating(id: IdType, rating: number){
    try {
      const start = async () => {
        await updateContactRating({ id, rating });
        this.setRating(rating);
      };
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error })
      });
    } catch (error) {
      console.log(error);
    } finally {
      const contactDetailsStore = this.coreStore.ContactDetailsStore;
      yield contactDetailsStore.setCurrentContact({
        ...this.contact!,
        rating
      });
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  resetStore() {
    this.accountPlansData = null;
    this.adviserData = null;
    this.contact = null;
    this.contactsDetails = null;
    this.contactSpouse = null;
    this.editIndex = null;
    this.id = null;
    this.nextCalendarActivityData = null;
    this.notes = null;
    this.predefinedTags = [];
  }
}
