import React, { useCallback, useState, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { GlobalTagList } from '@modules/ContactComponents/Tags/components';
import { Setting } from '@/assets';
import RowFilter from '@components/RowFilter';
import TagButton, { TAG_BUTTON_TYPES } from '@components/TagButton';

import { TAG_BUTTON_COLOR_SCHEME_VARIANTS } from '@modules/ContactComponents/Tags/data';
import { TAGS_FRONT_ID_KEY } from '@constants/common';
import { validationMessages } from '@constants/validationMessages';

import { TagsWrapper, ContentWrapper, TagTypeName, AdditionalButtonWrapper } from './styles';

import { TagsListProps } from './types';


const COUNT_OF_VISIBLE_TAGS = 4;

export const TagsList = observer(({
  ButtonStyles = {},
  InputStyles = {},
  store,
  tagName,
  type
}: TagsListProps) => {

  const {
    editIndex,
    errorMessage,
    onAdd,
    onDelete,
    onEditEnd,
    onEditStart,
    onSave,
    onSaveOldTag,
    setErrorMessage,
    tags,
  } = store;
  const { isLoad } = useStore().ContactDetailsStore;

  const validator = useCallback((value:string) => {
    if(value.length === 0){
      setErrorMessage(validationMessages.invalid);
      return false;
    }

    return true;
  },[setErrorMessage]);

  const isEditIndex = typeof editIndex === 'number';

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

  const isOpen = Boolean(anchorEl);

  const onExitingTagButtonClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, [ setAnchorEl ]);

  const onExitingTagClose = useCallback(() => {
    setAnchorEl(null);
  }, [ setAnchorEl ]);

  return(
    <>
      <ContentWrapper>
        <TagTypeName>
          { tagName }
        </TagTypeName>
        <TagsWrapper
          color={ ButtonStyles.color }
        >  
          <RowFilter
            $showMoreListMarginBottom={ '0px' }
            items={ tags as any }
            limit={ COUNT_OF_VISIBLE_TAGS }
            renderItem={ ({
              content,
              isShowMore,
              indexForKey,
              onClick,
            }) => {
              return isShowMore ?
                <TagButton
                  $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].button }
                  content={ content }
                  key={ content.id }
                  onClickCb={ onClick }
                  tagData={ content as any }
                  type={ TAG_BUTTON_TYPES.button }
                  style={ {
                    width: 'auto',
                  } }
                /> :
                <TagButton
                  $isDynamicInputWidth={ false }
                  $showError={ true }
                  $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].button }
                  content={ content?.label }
                  isNotEditable={ !(TAGS_FRONT_ID_KEY in content) ?? false }
                  key={ TAGS_FRONT_ID_KEY in content ? content[TAGS_FRONT_ID_KEY] : content.id }
                  onDeleteCb={ onDelete }
                  onEditEnd={ onEditEnd }
                  onEditStart={ onEditStart }
                  onSaveCb={ onSave }
                  disabled={ isEditIndex && !(TAGS_FRONT_ID_KEY in content) }
                  errorMessage={ editIndex === indexForKey ? errorMessage : null }
                  initEditState={ editIndex === indexForKey }
                  inputStyle={ InputStyles }
                  style={ ButtonStyles }
                  tagData={ content as any }
                  type={ TAG_BUTTON_TYPES.edit }
                  validator={ validator }
                />;
            } }
          >
            <TagButton
              $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].button }
              content={ '+' }
              onClickCb={ onAdd }
              // disabled={ Boolean(editIndex) }
              type={ TAG_BUTTON_TYPES.button }
            />
            <AdditionalButtonWrapper color={ ButtonStyles.color }>
              <TagButton
                $isActive={ Boolean(anchorEl) }
                $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].button }
                // @ts-ignore
                content={ <Setting width={ 16 }/> }
                onClickCb={ onExitingTagButtonClick }
                disabled={ Boolean(editIndex) }
                type={ TAG_BUTTON_TYPES.button }
                style={ {
                  padding: '2px 10px',
                } }
              />
            </AdditionalButtonWrapper>
          </RowFilter>
        </TagsWrapper>
      </ContentWrapper>

      { isOpen &&
        <GlobalTagList
          adminTags={ store.getFilteredGlobalTags() }
          anchorEl={ anchorEl }
          ButtonStyles={ ButtonStyles }
          InputStyles={ InputStyles }
          isOpen={ isOpen }
          isStoreFetching={ isLoad }
          onClose={ onExitingTagClose }
          onSave={ onSaveOldTag }
          type={ type }
        />
      }
    </>
  );
});
