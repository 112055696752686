import React from 'react';

import UserIcon, { getSubImageSize } from '@components/UserIcon';

import {
  Wrapper,
  UserIconWrapper
} from './styles';

import { AvatarProps } from './types';

export const Avatar = ({
  $color,
  $oneByOneType,
  $size,
  $subType,
  $withFrame,
  mainFullName,
  mainImageData,
  subFullName,
  subImageData,
}: AvatarProps) => {

  if ((subImageData?.abbr && $oneByOneType) || (subImageData?.src && $oneByOneType)) {
    return (
      <Wrapper $oneByOneType={ $oneByOneType }>
        <UserIconWrapper $withFrame={ true }>
          <UserIcon
            $color={ $color }
            $size={ $size }
            abbr={ mainImageData?.abbr }
            fullName={ mainFullName }
            imgSrc={ mainImageData?.src }
          />
        </UserIconWrapper>
        <UserIconWrapper $oneByOneType={ $oneByOneType }>
          <UserIcon
            $color={ $color }
            $size={ $size }
            abbr={ subImageData?.abbr }
            fullName={ subFullName }
            imgSrc={ subImageData?.src }
          />
        </UserIconWrapper>
      </Wrapper>
    );
  }

  if ((subImageData?.abbr && $subType) || (subImageData?.src && $subType)) {
    return (
      <Wrapper>
        <UserIconWrapper $withFrame={ $withFrame }>
          <UserIcon
            $color={ $color }
            $size={ $size }
            abbr={ mainImageData?.abbr }
            fullName={ mainFullName }
            imgSrc={ mainImageData?.src }
          />
        </UserIconWrapper>
        <UserIconWrapper
          $subType={ $subType }
          $withFrame={ true }
        >
          <UserIcon
            $color={ $color }
            $size={ getSubImageSize($size) }
            abbr={ subImageData?.abbr }
            fullName={ subFullName }
            imgSrc={ subImageData?.src }
          />
        </UserIconWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <UserIconWrapper
        $withFrame={ $withFrame }
      >
        <UserIcon
          $color={ $color }
          $size={ $size }
          abbr={ mainImageData?.abbr }
          fullName={ mainFullName }
          imgSrc={ mainImageData?.src }
        />
      </UserIconWrapper>
    </Wrapper>
  );
};
