import styled from 'styled-components';
import { COLORS } from '@constants/colors';

const HEIGHT = 600;

export const Spacer = styled.div`
  width: 1300px;
  height: ${HEIGHT}px;
`;


export const ContentWrapper = styled.div`
  display: flex;
  background-color: ${ COLORS.background };
  height: ${HEIGHT}px;
`;

export const LeftColumn = styled.div`
  width: 790px;
  padding: 25px 20px;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-right: 1px solid ${COLORS.greyLight};

  margin-right: 8px;
`;


export const RightColumn = styled.div`
  display: flex;
  width: 500px;

  & > div {
    width: inherit;
  }
`;

export const LeftWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-right: 20px;
`;


export const RightWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-left: 52px;
`;


