import { action, makeAutoObservable, flow } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { Store } from '@services/store/store';
import { getViewedContact } from '@services/api/common/common';

import { WEBSOCKET_MESSAGE_TYPES } from '@/shared/constants/common';

import {
  LastViewedContactsResponse,
  LastViewedContactsWebsocketData,
  LastViewedContactsWebsocketEventData,
  LastViewedContactsWebsocketMessageData,
  WebsocketLoginSettings
} from './types';
import { LastViewedContacts } from '@/shared/types/commonTypes';
import { LinkedContact } from '@/shared/types/linkedContact';

class AppHeaderStore {
  contact: LinkedContact | null = null;
  coreStore: Store;
  isLoad: boolean = false;
  recentContactsLists: LastViewedContacts = [];
  websocket: WebSocket | null = null;
  websocketChannelName: string | null = null;
  websocketNotificationCallback: (() => void) | null = null;
  websocketURL: string | null = null;
  currentContactSearchPage: number = 1;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getRecentContacts: flow.bound,
      init: action.bound,
      resetStore: action.bound,
      setContact: action.bound,
      increaseCurrentContactSearchPage: action.bound,
      resetCurrentContactSearchPage: action.bound,
    });

    makePersistable(this, {
      name: 'websocketData',
      properties: ['websocketChannelName', 'websocketURL'],
      storage: localStorage,
    }, {
      fireImmediately: false
    });

    this.coreStore = coreStore;
  }

  init() {
    this.connectWebsocket();
    this.getRecentContacts();
  }

  *getRecentContacts() {
    const profileId = this.coreStore.SettingsStore.profileId;
    if(this.recentContactsLists.length === 0 && profileId) {
      this.setIsLoad(true);
      try {
        const resp: LastViewedContactsResponse = yield getViewedContact();
        this.recentContactsLists = resp.data.data;
      } catch(error) {
        console.log(error);
      } finally {
        this.setIsLoad(false);
      }
    }
  }

  connectWebsocket() {
    if(this.websocketChannelName && this.websocketURL) {
      this.setIsLoad(true);
      try {
        this.websocket = new WebSocket(this.websocketURL);
        this.websocket.onopen = this.onWebsocketOpen.bind(this);
        this.websocket.onmessage = this.onWebsocketMessage.bind(this);
      } catch (error) {
        console.log(error);
      } finally {
        this.setIsLoad(false);
      }
    }
  }

  onWebsocketOpen() {
    const payload = {
      event: 'pusher:subscribe',
      data: {
        channel: this.websocketChannelName
      }
    };
    this.websocket?.send(JSON.stringify(payload));
  }

  onWebsocketMessage(event: MessageEvent<string>) {
    try {
      const eventData: LastViewedContactsWebsocketEventData = JSON.parse(event?.data);

      if(eventData?.data){
        const data: LastViewedContactsWebsocketData = JSON.parse(eventData?.data as string);

        if(data?.message){
          const message: LastViewedContactsWebsocketMessageData = JSON.parse(data?.message as string);
          if(message.messageType === WEBSOCKET_MESSAGE_TYPES.updateContactsList) {
            this.websocketNotificationCallback = () => {
              this.getRecentContacts();
            };
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  setWebsocketData(settings: WebsocketLoginSettings) {
    this.websocketChannelName = settings.websockets.channelName;
    this.websocketURL = settings.websockets.url;  
  }

  setLastViewedContactsList(contacts: Array<LinkedContact>) {
    this.recentContactsLists = contacts;
  }

  setContact(contact: LinkedContact | null) {
    this.contact = contact;
  }

  setIsLoad(state: boolean) {
    this.isLoad = state;
  }

  resetStore() {
    if(this.websocket) {
      this.websocket.close();
      this.websocket = null;
      this.websocketChannelName = null;
      this.websocketURL = null;
      this.recentContactsLists = [];
    }
  }

  increaseCurrentContactSearchPage() {
    this.currentContactSearchPage = this.currentContactSearchPage + 1;
  }

  resetCurrentContactSearchPage() {
    this.currentContactSearchPage = 1;
  }
}

export default AppHeaderStore;
