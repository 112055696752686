import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { AddCircleOutline, RemoveCircleOutline } from '@/assets';
import BlockButton from '@components/BlockButton';
import FormBlock from '@components/FormBlock';
import TagButton, { TAG_BUTTON_TYPES, TAG_BUTTON_VARIANTS } from '@components/TagButton';
import TagSearch from '../FormBlocks/TagsBlock/components/TagSearch';

import { checkInclusion, excludeOption } from './helpers';
import { MAX_LENGTH_OF_TAG, TAGS_FRONT_ID_KEY } from '@constants/common';
import { tagsTypes } from '@modules/AddContactModal/addContactStore/data/constantData';

import { AdminTagsList, ButtonsWrapper, TagsList, TAG_BUTTON_STYLES, VIEW_ALL_BUTTON_STYLES } from './styles';

import { CustomFieldTagItem, TagListItemNew } from '@/shared/types/tags';
import { TagsProps } from './types';


export const Tags = observer(({
  contactTags,
  setContactTags,
  tagsToSelect,
  tagType,
}: TagsProps) => {

  const [showAllTags, setShowAllTags] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const showAllTagsButtonLabel = useMemo(() => showAllTags ? 'Hide All' : 'View All', [showAllTags]);

  const addTagHandler = useCallback((tag: CustomFieldTagItem) => {
    if (!checkInclusion(tag, contactTags)) {
      setContactTags([...contactTags, tag]);
    }
  }, [setContactTags]);
  
  const addNewTagHandler = useCallback(() => {
    const newTag = {
      [TAGS_FRONT_ID_KEY]: uuidv4(),
      label: 'New Tag',
      type: tagsTypes.new,
      id: null,
    } as TagListItemNew;

    setContactTags([...contactTags, newTag]);
  }, [setContactTags]);

  const renameContactTag = useCallback((tag: TagListItemNew) => {
    const editIndex = contactTags.findIndex(item => 'frontId' in item && (item.frontId === tag.frontId));
    if (editIndex !== -1) {
      contactTags[editIndex].label = tag.label;
      setContactTags([...contactTags]);
    }
    setEditIndex(null);
  }, [setContactTags, setEditIndex, contactTags]);
  
  const deleteContactTag = useCallback((tag: CustomFieldTagItem) => {
    setContactTags(excludeOption(tag, contactTags));
  }, [setContactTags]);

  const onEditStart = useCallback((tag: TagListItemNew) => {
    const editIndex = contactTags.findIndex(item => 'frontId' in item && (item.frontId === tag.frontId));
    setEditIndex(editIndex);
  }, [setEditIndex, contactTags]);

  const selectChangeHandler = useCallback((tag: CustomFieldTagItem) => {
    if (checkInclusion(tag, contactTags)) {
      deleteContactTag(tag);
    } else {
      setContactTags([...contactTags, tag]);
    }
  }, [setContactTags, deleteContactTag]);
  
  const onSaveCb = useCallback((data: TagListItemNew, saveEndCallback: () => void) => {
    renameContactTag(data);
    saveEndCallback();
  }, [renameContactTag]);

  return (
    <>
      <FormBlock.FormField
        $labelWidth={ 109  }
        label={ `${tagType} Tags` }
      >
        <TagSearch
          noOptionsText={ 'No results' }
          onChange={ selectChangeHandler }
          options={ tagsToSelect }
          placeholder={ `Start Typing ${tagType} Tag Name...` }
          selectedTags={ contactTags }
        />
      </FormBlock.FormField>

      <FormBlock.FormField
        $labelWidth={ 109  }
        label={ ' ' }
      >
        <ButtonsWrapper isOpened={ showAllTags }>
          <TagsList>
            { contactTags.map((tag, index) => (
              <TagButton
                $isDynamicInputWidth={ true }
                initEditState={ editIndex === index }
                isNotEditable={ Boolean(tag.id) }
                key={ TAGS_FRONT_ID_KEY in tag ? tag[TAGS_FRONT_ID_KEY] : tag.id }
                maxLength={ MAX_LENGTH_OF_TAG }
                onDeleteCb={ deleteContactTag }
                onEditStart={ onEditStart }
                onSaveCb={ onSaveCb }
                style={ TAG_BUTTON_STYLES }
                tagData={ tag }
                type={ TAG_BUTTON_TYPES.edit }
              />
            )) }
            <TagButton
              content={ '+' }
              onClickCb={ addNewTagHandler }
              type={ TAG_BUTTON_TYPES.button }
            />
          </TagsList>
          
          <BlockButton
            endIcon={ showAllTags ? <AddCircleOutline /> : <RemoveCircleOutline /> }
            onClick={ () => setShowAllTags(!showAllTags) }
            style={ VIEW_ALL_BUTTON_STYLES }
          >
            { showAllTagsButtonLabel }
          </BlockButton>
        </ButtonsWrapper>
      </FormBlock.FormField>

      { showAllTags &&
        <FormBlock.FormField
          $labelWidth={ 109  }
          label={ ' ' }
        >
          <AdminTagsList>
            { tagsToSelect.map(tag => (
              <TagButton
                $variant={ TAG_BUTTON_VARIANTS.system }
                content={ tag.label }
                key={ tag.id }
                onClickCb={ () => addTagHandler(tag) }
                type={ TAG_BUTTON_TYPES.button }
              />
            )) }
          </AdminTagsList>
        </FormBlock.FormField>
      }
    </>
  );
});
