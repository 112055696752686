import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import Surface from '@components/BasicLayoutComponents/Surface';
import SalesClientOpportunityPopup from '@modules/SalesPipline/SalesClientOpportunityPopup';

import { MODAL_TYPE } from '@constants/modalTypes';

import {
  SalesClientOpportunitiesDeletePopup,
  SalesClientOpportunitiesFilters,
  SalesClientOpportunitiesPreHeader,
  SalesClientOpportunitiesTable,
} from './components';

import { OnAddEditClientOpportunity, OnRemoveClientOpportunities } from '@/shared/types/salesCycleOpportunity';


export const SalesClientOpportunities = observer(() => {
  const {
    init,
    resetStore,
    isFiltersOpen,
    onSave,
    onRemove,
  } = useStore().SalesCycleStore.SalesClientOpportunitiesTable;
  const modalStore = useStore().ModalStore;

  const onRemoveClientOpportunity = useCallback<OnRemoveClientOpportunities>((data) => {
    const onConfirm = () => onRemove(data);

    modalStore.openModal({
      component: SalesClientOpportunitiesDeletePopup,
      modalProps: {
        onConfirm
      },
      modalType: MODAL_TYPE.REMOVE_CLIENT_OPPORTUNITY,
    });
  }, [modalStore, onRemove]);

  const onAddEditClientOpportunity = useCallback<OnAddEditClientOpportunity>((id) => {
    modalStore.openModal({
      component: SalesClientOpportunityPopup,
      modalProps: {
        id,
        onSaveCallback: onSave,
        onDeleteCallback: onRemoveClientOpportunity
      },
      modalType: MODAL_TYPE.ADD_CLIENT_OPPORTUNITY,
    });
  }, [modalStore, onRemoveClientOpportunity, onSave]);


  useEffect(() => {
    init();
    return resetStore;
  }, [init, resetStore]);

  return (
    <Surface.Content $isWrap={ false } $isRightSidePanel>
      <Surface.Main>
        <SalesClientOpportunitiesPreHeader
          onAdd={ onAddEditClientOpportunity }
          onRemove={ onRemoveClientOpportunity }
        />
        <SalesClientOpportunitiesTable
          onAddEdit={ onAddEditClientOpportunity }
          onRemove={ onRemoveClientOpportunity }
        />
      </Surface.Main>
      <Surface.Side isFiltersWithButtonOpen={ isFiltersOpen }>
        <SalesClientOpportunitiesFilters/>
      </Surface.Side>
    </Surface.Content>
  );
});
