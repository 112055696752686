import React from 'react';

import Tooltip from '@components/Tooltip';

import { CONTACT_TYPE } from '@modules/AddContactModal/data';
import { HOUSEHOLD_TYPE_BUTTON_TOOLTIP, INDIVIDUAL_TYPE_BUTTON_TOOLTIP } from './data';

import { Container, Tab, Text } from './styles';

import { TabBarProps } from './types';


export const TabBar = ({
  contactTypes,
  onChange,
  selectedType
}: TabBarProps) => {
  return (
    <Container>
      {contactTypes.map(({ label, value }) => {
        const title = value === CONTACT_TYPE.Individual ?
          INDIVIDUAL_TYPE_BUTTON_TOOLTIP :
          HOUSEHOLD_TYPE_BUTTON_TOOLTIP;
          //eslint-disable-next-line
        return (
          <Tab 
            $selected={ value === selectedType }
            key={ value } 
            onClick={ () => onChange(value) } 
          >
            { value !== CONTACT_TYPE.Business &&
              <Tooltip title={ title }>
                <Text>{label}</Text>
              </Tooltip>
            }

            { value === CONTACT_TYPE.Business &&
              <Text>{label}</Text>
            }
          </Tab>);
      })}
    </Container>
  );
};
