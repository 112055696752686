import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { StepLabelProps, StepProps } from '@material-ui/core';

import { getStepState } from './utils';

import {
  Icon,
  LabelText,
  StepName,
  StepNumber,
  StyledStep,
  StyledStepConnector,
  StyledStepLabel,
  StyledStepper
} from './styles';

import { StepperProps } from './types';


export const Stepper = observer(({
  activeStep,
  onChangeStep,
  steps,
}: StepperProps) => {

  const onClick = useCallback((step: number) => {
    onChangeStep(step);
  }, [onChangeStep]);

  return (
    <StyledStepper
      activeStep={ activeStep }
      connector={ <StyledStepConnector/> }
    >
      { steps.map((label, index) => {
        const currentStep = index + 1;
        const stepState = getStepState(activeStep, currentStep);

        const stepProps = {} as StepProps;
        const labelProps = {} as StepLabelProps;

        if(label.length === 1) {
          labelProps.optional = <LabelText>{ label }</LabelText>;
        }
        // in case we have two lines in the step name
        if(label.length > 1 && typeof label !== 'string') {
          labelProps.optional = (
            <StepName>
              { label.map((text: string) => (
                <LabelText key={ text }>
                  { text }
                </LabelText>
              ))}
            </StepName>
          );
        }
        return (
          <StyledStep
            key={ index }
            onClick={ () => onClick(currentStep) }
            { ...stepProps }
          >
            <StyledStepLabel
              $stepState={ stepState }
              icon={ <Icon $stepState={ stepState }/> }
              { ...labelProps }
            >
              <StepNumber>{ currentStep }</StepNumber>
            </StyledStepLabel>
          </StyledStep>
        );
      }) }
    </StyledStepper>
  );
});
