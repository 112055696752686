import {
  UserCustomFieldDropDownOptionFieldNamesType,
  UserCustomFieldNamesType,
  UserCustomFieldType
} from '@/shared/types/userSettingsUserCustomFields';

import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { TagFiltersFieldsNames } from '@/shared/types/tags';

export const USER_CUSTOM_FIELDS_TYPES = {
  dateField: 'Date field',
  dropDownList: 'Dropdown list',
  emailField: 'Email field',
  phoneField: 'Phone field',
  textArea: 'Text area',
  textField: 'Text field',
} as const;

export const USER_CUSTOM_FIELDS_TYPES_LABELS: Record<UserCustomFieldType, string> = {
  [USER_CUSTOM_FIELDS_TYPES.textField]: 'Text Field',
  [USER_CUSTOM_FIELDS_TYPES.textArea]: 'Text Area',
  [USER_CUSTOM_FIELDS_TYPES.emailField]: 'Email Field',
  [USER_CUSTOM_FIELDS_TYPES.dateField]: 'Date Field',
  [USER_CUSTOM_FIELDS_TYPES.phoneField]: 'Phone Field',
  [USER_CUSTOM_FIELDS_TYPES.dropDownList]: 'Dropdown List'
};

export const USER_CUSTOM_FIELDS_TYPES_OPTIONS = optionsFromValue(
  Object.values(USER_CUSTOM_FIELDS_TYPES),
  label => USER_CUSTOM_FIELDS_TYPES_LABELS[label as UserCustomFieldType]
);

export const USER_CUSTOM_FIELD_ENTITY = {
  contact: 'Contact',
  account: 'Account',
} as const;

export const USER_CUSTOM_FIELDS_ENTITY_OPTIONS = optionsFromValue(Object.values(USER_CUSTOM_FIELD_ENTITY));

export const USER_CUSTOM_FIELD_FORM_FIELDS: UserCustomFieldNamesType  =  {
  entity: 'entity',
  id: 'id',
  name: 'name',
  options: 'options',
  type: 'type'
};

export const USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES: UserCustomFieldDropDownOptionFieldNamesType = {
  isUsed: 'isUsed',
  id: 'id',
  label: 'label',
};

export const TAG_FILTERS_FIELDS_NAMES: TagFiltersFieldsNames = {
  from: 'from',
  to: 'to',
};
