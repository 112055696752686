import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import Surface from '@components/BasicLayoutComponents/Surface';
import Preloader from '@components/Preloader';
import { AppPage } from '@modules/AppPage';

import { useStore } from '@store';
import { useToggle } from '@customHooks';

import {
  Calendar,
  Filters,
} from './components';

const NewCalendar = () => {
  const calendarStore = useStore().CalendarStore;
  const { isCalendarLoad, isFiltersOpen } = calendarStore;

  useEffect(() => {
    calendarStore.initPage();
    return () => calendarStore.resetStore();
  }, []);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppPageTitle title={ 'Calendar' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isCalendarLoad && <Preloader />
        }
        <Surface.ContentWithoutHeader $isWrap={ false } $isRightSidePanel>
          <Surface.Main>
            <Calendar/>
          </Surface.Main>
          <Surface.Side isFiltersWithButtonOpen={ isFiltersOpen }>
            <Filters />
          </Surface.Side>
        </Surface.ContentWithoutHeader>
      </Surface>
    </AppPage>
  );
};


export default observer(NewCalendar);
