import { convertUTCFormatToStartEndDateString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { typeConverter } from '@/shared/utils/typeConverter';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { CORE_CLASSIFICATIONS } from '@constants/common';

import { Client, ClientWithCalendarData } from '@/shared/types/commonTypes';
import { ClientConverter, EditActivityItem } from '@/shared/types/activityPopup';
import { EditActivityItemResponse } from '../types';
import { LinkedContact } from '@/shared/types/linkedContact';

const clientConverter = (clients: Array<ClientWithCalendarData>): Array<ClientConverter> => {
  if(!clients) {
    return [];
  }

  return clients.map((client: ClientWithCalendarData) => ({
    firstName: client.firstName,
    lastName: client.lastName,
    photo: client.headshot,
    id: client.id,
    calendarEndTime: client.calendarEndTime,
    calendarStartTime: client.calendarStartTime,
    classification: CORE_CLASSIFICATIONS.CLIENT,
    fullName: `${client.firstName} ${client.lastName}`,
    abbr: client.firstName.charAt(0) + client.lastName.charAt(0),
  }));
};

const contactConverter = (contact: LinkedContact): LinkedContact => {
  if(!contact) {
    return {} as LinkedContact;
  }

  return contact;
};

export const activityNormalizer = (item: EditActivityItemResponse): EditActivityItem => {
  const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime,
    isAllDay: yesNoToBoolean(item.allDay)
  });

  return {
    formData: {
      allDay: yesNoToBoolean(item.allDay),
      description: item.description,
      endDate,
      endTime,
      forDay: item?.forDay,
      id: item.id,
      name: item.name,
      primaryClientId: item.primaryClientId,
      primaryContactId: item.primaryContactId,
      recurring: yesNoToBoolean(item.recurring),
      //recurringEndDate: if "null" came from backend, we cannot set defaultValue (UseHookForm)
      recurringEndDate: item.recurringEndDate ?? undefined,
      recurringFrequency: item.recurringFrequency,
      startDate,
      startTime,
      statusId: item.statusId,
      subcategoryId: item.subcategoryId,
      type: typeConverter({
        isBackend: true,
        type: item.type,
      }),
      clients: item?.clients?.map((client: Client) => ({ userId: client.id })) || []
    },
    renderData: {
      primaryContact: contactConverter(item?.contact),
      clients: clientConverter(item?.clients)
    }
  };
};
