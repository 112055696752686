import React from 'react';
import { observer } from 'mobx-react-lite';

import { tagsTypes } from '@modules/AddContactModal/addContactStore/data/constantData';

import { Tags } from '@modules/AddContactModal/components';
import FormBlock from '@components/FormBlock';

function TagsBlock({ store, isInterestTags = false }) {
  const setGeneralTags = (generalTags) => {
    store.setSelectedTags(generalTags, tagsTypes.general);
  };

  const setInterestTags = (interestTags) => {
    store.setSelectedTags(interestTags, tagsTypes.interest);
  };

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Tags' } />
      <FormBlock.RowWrapper flexDirection={ 'column' }>
        <Tags
          contactTags={ store.selectedTags.general }
          setContactTags={ setGeneralTags }
          tagType='General'
          tagsToSelect={ store.contactTags.general }
        />
        {isInterestTags && (
          <Tags
            contactTags={ store.selectedTags.interest }
            setContactTags={ setInterestTags }
            tagType='Interest'
            tagsToSelect={ store.contactTags.interest }
          />
        )}
      </FormBlock.RowWrapper>
    </FormBlock>
  );
}

export default observer(TagsBlock);
