import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { useToggle } from '@customHooks';

import AddContactModal from '@modules/AddContactModal';
import DeleteContactsPopup from '@modules/DeleteContactsPopup';
import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';
import { AppPage } from '@modules/AppPage';
import { ContactsTable, Filters, PageHeader, PreTablePanel } from './components';

import { MODAL_TYPE } from '@constants/modalTypes';

import { IdType } from '@/shared/types/commonTypes';

export const AllContacts = observer(() => {
  const contactsStore = useStore().ContactsStore;
  const { id: currentUserId } = useStore().SettingsStore.profile;
  const { trashStorageDays } = useStore().TrashStore;
  const { isFetching, saveContact } = contactsStore;
  const modalStore = useStore().ModalStore;

  const [ isFiltersRendered, setIsFiltersRendered ] = useToggle(false);

  const addContactHandler = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CONTACT_MODAL,
      modalProps: {
        stickToTop: false,
        title: 'New Contact',
        saveCallback: saveContact,
        currentUserId,
      },
      component: AddContactModal,
    });
  }, [currentUserId, modalStore, saveContact]);

  const deleteContactHandler = useCallback((arrayOfIds: Array<IdType>) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_CONTACTS_POPUP,
      modalProps: {
        arrayOfIds,
        numberOfDaysUntilDeletion: trashStorageDays,
        onSave: contactsStore.deleteContacts.bind(contactsStore)
      },
      component: DeleteContactsPopup,
    });
  }, [contactsStore, modalStore]);

  useEffect(() => {
    contactsStore.initPage();
    return () => {
      contactsStore.resetState();
    };
  }, [contactsStore]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppPageTitle title={ 'All Contacts' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isFetching && <Preloader />
        }
        <PageHeader
          isFiltersBtnActive={ isFiltersRendered }
          onFiltersClick={ setIsFiltersRendered }
        />
        <Surface.Content $isWrap={ false } $isRightSidePanel>
          <Surface.Main>
            <PreTablePanel
              onAddContactClick={ addContactHandler }
              onDeleteContactsClick={ deleteContactHandler }
            />
            <ContactsTable
              addContactHandler={ addContactHandler }
              deleteContactHandler={ deleteContactHandler }
            />
          </Surface.Main>
          <Surface.Side isFiltersWithButtonOpen={ isFiltersRendered }>
            <Filters />
          </Surface.Side>
        </Surface.Content>
      </Surface>
    </AppPage>
  );
});
