import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@/shared/services/store/store';
import { MODAL_TYPE } from '@/shared/constants/modalTypes';

import { ProfilePanel } from './components/ProfilePanel/ProfilePanel';
import { UsersRow } from './components/UsersRow/UsersRow';
import AddUserPopup from './components/AddUserPopup';
import BlockButton from '@/components/BlockButton';
import ContentCenter from '@/components/ContentCenter';
import PreTableWrapper from '@/components/BasicLayoutComponents/PreTableWrapper';
import Surface from '@/components/BasicLayoutComponents/Surface';
import Table from '@/components/Table';
import DeleteUserPopup from './components/DeleteUserPopup';

import NoDataItemsView from '@/modules/NoDataItemsView';
import { getIsAbleToSetUserPermission } from '@/pages/NewUserSettings/utils';

import { PlusWithCircle } from '@/assets';

import { USER_COLUMNS } from './data';

import { BtnWrapper } from './styles';

import { DataItemModalProps } from './components/DeleteUserPopup/types';
import { IdType } from '@/shared/types/commonTypes';
import { UserProfileType } from '@services/store/userSettingsStore/types';

const SURFACE_MAIN_STYLES = {
  minWidth: 650,
  marginRight: 20
};

export const Users = observer(() => {

  const modalStore = useStore().ModalStore;

  const { roles } = useStore().SettingsStore;
  const userSettingsStore = useStore().UserSettingsStore;
  const {
    isFetching,
    table
  } = userSettingsStore;

  const [ isProfilePanelOpened, setIsProfilePanelOpened ] = useState(false);
  const [ selectedRowId, setSelectedRowId ] = useState<IdType | null>(null);

  const isAbleSetPermissions = getIsAbleToSetUserPermission(roles);

  const openAddUserPopup = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_USER,
      component: AddUserPopup,
      modalProps: {
        updateUsersList: userSettingsStore.getListUsers.bind(userSettingsStore),
      },
    });
  }, [userSettingsStore, modalStore]);

  const openDeleteUserPopup = useCallback((dataItem: DataItemModalProps) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_USER,
      component: DeleteUserPopup,
      modalProps: {
        dataItem
      },
    });
  }, [modalStore]);

  const onViewOrEditClick = useCallback(async (id: number) => {
    await userSettingsStore.getUserProfile(id);
    setSelectedRowId(id);
    setIsProfilePanelOpened(true);
  }, [ userSettingsStore, setIsProfilePanelOpened, setSelectedRowId ]);

  const onCloseProfilePanel = useCallback(() => {
    setSelectedRowId(null);
    setIsProfilePanelOpened(false);
  }, [ setIsProfilePanelOpened, setSelectedRowId ]);

  const onAddUserClick = useCallback(() => {
    openAddUserPopup();
  }, [ openAddUserPopup ]);

  const onDeleteUserClick = useCallback(({ id, firstName, lastName }: DataItemModalProps) => {
    openDeleteUserPopup({ id, firstName, lastName });
  }, [ openDeleteUserPopup ]);

  useEffect(() => {
    userSettingsStore.getListUsers();
    return () => userSettingsStore.reset();
  }, [ userSettingsStore ]);

  return (
    <Surface.Content $isWrap={ false } $isRightSidePanel>
      <Surface.Main style={ SURFACE_MAIN_STYLES }>
        <PreTableWrapper>
          <BtnWrapper>
            <BlockButton
              disabled={ isProfilePanelOpened }
              onClick={ onAddUserClick }
              startIcon={ <PlusWithCircle /> }
            >
              Add User
            </BlockButton>
          </BtnWrapper>
        </PreTableWrapper>
        <Table>
          <Table.Container triggerScrollToTop={ table.currentPage }>
            <Table.Header
              columns={ USER_COLUMNS }
              onSortChangeCb={ table.setMultipleSorting }
              sortingState={ table.multipleSorting }
            />
            <Table.Body>
              {
                table.items.length > 0 &&
                table.items.map((dataItem: UserProfileType) => (
                  <UsersRow
                    dataItem={ dataItem }
                    isProfilePanelOpened={ !isProfilePanelOpened }
                    key={ dataItem.id }
                    onDeleteUserClick={ onDeleteUserClick }
                    onViewOrEditClick={ onViewOrEditClick }
                    selectedRowId={ selectedRowId }
                  />
                ))
              }
            </Table.Body>
            {
              !isFetching && table.items.length === 0 &&
              <ContentCenter>
                <NoDataItemsView/>
              </ContentCenter>
            }
          </Table.Container>
          <Table.Footer
            currentPage={ table.currentPage }
            onPageChangeCb={ table.setCurrentPage }
            pagesCount={ table.totalPages }
          >
            <Table.FooterCountInfo
              from={ table.itemsFrom }
              to={ table.itemsTo }
              total={ table.totalItems }
            />
          </Table.Footer>
        </Table>
      </Surface.Main>
      { isProfilePanelOpened &&
        <Surface.Side isFiltersPanel={ false }>
          <ProfilePanel
            isAbleSetPermissions={ isAbleSetPermissions }
            onCloseClick={ onCloseProfilePanel }
          />
        </Surface.Side>
      }
    </Surface.Content>
  );
});
