import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';
import { useLocation } from 'react-router-dom';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import FetchAutoComplete from '@modules/FetchAutoComplete';
import UserItem from '@components/NewUserItem';

import { Search } from '@/assets';

import { StyledTextField, EndAdornment, SearchWrapper, SelectedUserWrapper } from './styles';

import { getFullName, getParamsToSearchContactNamePhoneEmail } from '@/shared/utils/fetchAutocompleteUtils';
import { searchContactByNamePhoneEmail } from '@services/api/common/common';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { CONTACT_TYPES } from '@constants/common';
import { LinkedContact } from '@/shared/types/linkedContact';



const contactResponseConverter = (response: any) => {
  const data = response?.data?.data?.data;
  return data ? Object.values(data) : [];
};


export const ContactsSearch = observer(() => {
  const routerStore = useStore().RouterStore;
  const {
    contact,
    setContact,
  } = useStore().AppHeaderStore;

  const [inputValue, setInputValue] = useState<string>('');

  const location = useLocation();

  const onDeleteWrapper = useCallback(() => {
    setContact(null);
    setInputValue('');
  },[setContact]);

  const onChange = useCallback((data: LinkedContact) => {
    setContact(data);
    setInputValue('');

    if(data) {
      routerStore.moveToContact({
        id: data?.id,
        contactType: data?.type,
        tab: CONTACT_DETAILS_TAB.overview,
      });
    }
  }, [routerStore, setContact]);

  const searchValidation = useMemo(() => (value: string) => (
    value.replace(/^@/, '').replace(/^\(/, '').replace(/^\)/, '').replace(/^\./, '')
  ), []);

  useEffect(() => {
    if(!location.pathname.includes(CONTACT_DETAILS_TAB.overview) && contact){
      setContact(null);
    }
  }, [contact, location, setContact]);

  return (
    <FetchAutoComplete
      AutocompleteProps={ {
        getOptionLabel: (option: any) => option.fullName || getFullName(option) || 'label',
        filterOptions: (options: any) => options,
        renderOption: (option: any) => {
          if(option.type === CONTACT_TYPES.HOUSEHOLD && !option.householderContacts ||
            option.type === CONTACT_TYPES.INDIVIDUAL && (!option.firstName || !option.lastName) ||
            option.type === CONTACT_TYPES.BUSINESS && !option.occupationCompany && !option.fullName) {
            return null;
          }
          return (
            <UserItem
              containerStyle={ {
                paddingLeft: '10px',
              } }
              user={ option }
              withCoreClassification={ true }
            />);
        },
        renderInput: (params) => (
          <StyledTextField
            { ...params }
            InputProps={ {
              ...params.InputProps,
              startAdornment: (
                contact
                  ? (
                    <SelectedUserWrapper>
                      <UserItem
                        labelWidth={ 370 }
                        user={ contact }
                      />
                    </SelectedUserWrapper>
                  )
                  : null
              ),
              endAdornment: (
                <EndAdornment>
                  {
                    (inputValue.length > 0 || contact) &&
                    <CustomIconButton
                      onClick={ onDeleteWrapper }
                      type={ ICON_BUTTON_TYPES.cross }
                    />
                  }
                  <SearchWrapper>
                    <Search/>
                  </SearchWrapper>
                </EndAdornment>
              )
            } }
            placeholder={ contact ? '' :'Type to search Contacts, Phones, Emails...' }
            variant='outlined'
          />
        )
      } }
      FetchProps={ {
        getParams: getParamsToSearchContactNamePhoneEmail,
        requestSender: searchContactByNamePhoneEmail,
        responseConverter: contactResponseConverter
      } }
      onChange={ onChange }
      outSideInputValue={ inputValue }
      searchValidation={ searchValidation }
      setOutSideInputValue={ setInputValue }
    />
  );
});
