
import { ID_OF_USASTATES } from '@constants/common';
import { getCountries, getStates } from '@services/api/common/common';
import { makeAutoObservable, flow, action } from 'mobx';
import { merge } from 'lodash';

import getContactOptions from '@services/api/addContact/getContactOptions';
import { getTagsList } from '@services/api/tags/tags';


import {
  CONTACT_TYPES_OPTIONS,
  initialStep,
  initialType,
  tagsTypes
} from './data/constantData';
import { getDefaultValues } from './data/formValues';
import {
  getContactRoute,
  getErrorStep,
  getTagsInitState,
  optionsNormilizer,
  TagsNormilizer
} from './normilizers/parseNormilizer';
import { getStepData } from './normilizers/helpers';
import { getValidationSchema } from './data/validationSchemes';
import { saveContactNormilizer } from './normilizers/saveNormilizer';
class AddContactStore {
  contactTypes = CONTACT_TYPES_OPTIONS;

  currentUserId = null;

  formValues = {};

  contactTags = getTagsInitState();

  selectedTags = getTagsInitState();

  isFetching = false;

  spouseContact = null;

  linkedContact = null;

  relatedContacts = [];

  options = {};

  selectedStep = initialStep;

  selectedType = initialType;

  stepData = [];

  validationSchema = {};

  saveCallback = () => {};
  closeCallback = () => {};

  constructor() {
    makeAutoObservable(this, {
      getContactOptions: flow,
      getContactTags: flow,
      saveContact: action.bound,
      setSaveCallback: action.bound,
      setCloseCallback: action.bound,
      resetStore: action.bound,
      setCurrentUserId: action.bound
    });
  }

  setIsFetching(state) {
    this.isFetching = state;
  }

  setSaveCallback(callback) {
    this.saveCallback = callback;
  }

  setCurrentUserId(id){
    this.currentUserId = id;
  }

  setCloseCallback(callback) {
    this.closeCallback = callback;
  }

  setExistingTags(data) {
    this.contactTags = data;
  }

  setLinkedContact(data) {
    this.linkedContact = data;
  }

  setOptions(data) {
    this.options = data;
  }

  setSelectedType(value) {
    this.selectedType = value;
  }

  setSelectedStep(step, formValues) {
    this.updateValues(formValues);
    this.selectedStep = step;
  }

  setSelectedTags(data, type) {
    if (type === tagsTypes.general) {
      this.selectedTags.general = data;
    } else {
      this.selectedTags.interest = data;
    }
  }

  setSpouseContact(contact) {
    this.spouseContact = contact;
  }

  setRelatedContacts(data) {
    this.relatedContacts = data;
  }

  setFormValues(data) {
    this.formValues = data;
  }

  setValidationSchema(schema) {
    this.validationSchema = schema;
  }

  setStepData() {
    this.stepData = getStepData(this.selectedType);
  }

  *getContactOptions() {
    this.setIsFetching(true);
    this.resetFormValues();
    this.setFormValues(getDefaultValues(this.selectedType, this.currentUserId));
    this.setStepData();
    this.setValidationSchema(getValidationSchema(this.selectedType));

    try {
      const route = getContactRoute(this.selectedType);
      const optionsResponse = yield getContactOptions(route);
      const statesOptionsResponse = yield getStates({ id: ID_OF_USASTATES });
      const countriesOptionsResponse = yield getCountries();
      this.setOptions(optionsNormilizer(
        statesOptionsResponse.data.data,
        countriesOptionsResponse.data.data,
        optionsResponse.data.data,
        this.selectedType
      ));
    } catch (error) {
      // TODO do something with error here
    } finally {
      this.setIsFetching(false);
    }
  }

  saveContact() {
    const data= saveContactNormilizer(this.formValues, this);
    this.closeCallback();
    this.saveCallback(data);
  }

  *getContactTags() {
    try {
      const response = yield getTagsList();
      this.setExistingTags(TagsNormilizer(response.data.data));
    } catch (error) {
      // TODO do something with error here
    }
  }

  resetFormValues() {
    this.spouseContact = null;
    this.linkedContact = null;
    this.relatedContacts = [];
    this.selectedTags = getTagsInitState();
    this.selectedStep = initialStep;
  }

  updateValues(formValues) {
    this.formValues = merge(this.formValues, formValues);
  }

  validateForm(formValues) {
    this.updateValues(formValues);
    try {
      this.validationSchema.validateSync(this.formValues, {
        abortEarly: false
      });
      return false;
    } catch (error) {
      return getErrorStep(error.inner);
    }
  }

  resetStore() {
    this.selectedType = initialType;
    this.currentUserId = null;
    this.options = {};
  }
}

export default AddContactStore;
