import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import AddContactToList from '@modules/AddContactToList';
import Surface from '@components/BasicLayoutComponents/Surface';
import RemoveContactsPopup from '@modules/Lists/RemoveContactsPopup';
import {
  ListContactsPretableHeader,
  ListContactsSideFilters,
  ListContactsTable,
} from './components';

import { MODAL_TYPE } from '@constants/modalTypes';

import { ListContactsProps } from './types';

export const ListContacts = observer(({
  listType,
  listId
}: ListContactsProps) => {
  const modalStore = useStore().ModalStore;
  const listContactsStore = useStore().ListsStore.ListContactsStore;
  const {
    isListContactsFiltersOpen,
    currentListDetails,
  } = listContactsStore;

  const onContactAddClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CONTACT_TO_LIST,
      modalProps: {
        listId,
        listName: currentListDetails?.name || '',
        onSave: listContactsStore.addContactsToList.bind(listContactsStore)
      },
      component: AddContactToList,
    });
  }, [currentListDetails?.name, listContactsStore, listId, modalStore]);

  const onContactRemoveClick = useCallback((data: Array<string | number> | string | number) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.REMOVE_CONTACTS_FROM_LIST,
      modalProps: {
        contactsIds: Array.isArray(data) ? data : [data],
        onSave: listContactsStore.removeContactsFromList.bind(listContactsStore)
      },
      component: RemoveContactsPopup,
    });
  }, [listContactsStore, modalStore]);

  useEffect(() => {
    listContactsStore.init(listId);
    return () => listContactsStore.resetState();
  },[listContactsStore, listId]);

  return (
    <Surface.Content $isWrap={ false } $isRightSidePanel>
      <Surface.Main>
        <ListContactsPretableHeader
          listType={ listType }
          onContactAddClick={ onContactAddClick }
          onRemoveContactsClick={ onContactRemoveClick }
        />
        <ListContactsTable
          listType={ listType }
          onAddContactClick={ onContactAddClick }
          onRemove={ onContactRemoveClick }
        />
      </Surface.Main>
      <Surface.Side isFiltersWithButtonOpen={ isListContactsFiltersOpen }>
        <ListContactsSideFilters/>
      </Surface.Side>
    </Surface.Content>
  );
});
