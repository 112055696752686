import styled, { css } from 'styled-components';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import { COLORS } from '@constants/colors';
import { STEP_STATES } from './data';

import { IconProps, StepStates, StyledStepLabelProps } from './types';

export const StyledStepper = styled(Stepper)`
  width: 100%;
  padding: 0px;
`;

export const StyledStep = styled(Step)`
  padding: 0px;
  position: relative;

  & .Mui-disabled {
    cursor: pointer;
  }

  &:hover {
    cursor: pointer;
    & .MuiStepLabel-labelContainer {
      &, & > span > div {
        color: ${COLORS.helpText};
      }
    }

    & .MuiStepLabel-iconContainer > div {
      border-color: ${COLORS.orange};
    }
  }
`;

export const LabelText = styled.div`
  width: max-content;
  font-size: 12px;
  line-height: 18px;
`;

export const StepName = styled.div`
  display: block;
`;

export const StepNumber = styled.div`
  margin-right: 5px;
  font-size: 32px;
  font-weight: 300 !important;
  color: ${COLORS.grey};
`;

export const getLabelContainerColor = (stepState: StepStates) => {
  if(stepState === STEP_STATES.completed) {
    return COLORS.helpText;
  }
  if(stepState === STEP_STATES.active) {
    return COLORS.primaryText;
  }
  return COLORS.grey;
};

export const StyledStepLabel = styled(StepLabel)<StyledStepLabelProps>`
  padding: 0px;
  
  & .MuiStepLabel-labelContainer {
    display: flex;
    align-items: center;
    width: auto;
    position: absolute;
    bottom: 20px;

    &, & > span > div {
      color: ${({ $stepState }) => getLabelContainerColor($stepState) };
      ${({ $stepState }) => $stepState === STEP_STATES.active && css`font-weight: 500;` }
    }
  }

  & .MuiStepLabel-iconContainer {
    padding: 0px;
  }
`;

export const StyledStepConnector = styled(StepConnector)`
  & > span {
    border: 1px solid ${COLORS.borders};
  }

  &.MuiStepConnector-active {
    & > span {
      border-image: linear-gradient(to right, ${COLORS.orange}, ${COLORS.borders});
      border-image-slice: 1;
    }
  }

  &.MuiStepConnector-completed {
    & > span {
      border-color: ${COLORS.orange};
    }
  }
`;

export const Icon = styled.div<IconProps>`
  width: 16px;
  height: 16px;
  background-color: ${COLORS.background};
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;

  ${({ $stepState }) => {
    if($stepState === STEP_STATES.completed) {
      return css`
        border-color: ${COLORS.orange};
        background-color: ${COLORS.white};
      
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: ${COLORS.orange};
          position: absolute;
          top: 5px;
          left: 5px;
        }
      `;
    }
    if($stepState === STEP_STATES.active) {
      return css`
        border-color: ${COLORS.orange};
        background-color: ${COLORS.white};
      `;
    }
    return css`border-color: ${COLORS.borders};`;
  }}
`;
