import styled from 'styled-components';

export const FilterWrapper = styled.div`
  margin: 0 20px 0 0;
  z-index: 10;
`;

export const DrawerWrapper = styled.div`
  flex: none;
  height: 100%;
  width: 12px;
  margin-left: 8px;
  z-index: 11;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;