import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

import { COLORS } from '@constants/colors';

import { StyledTextFieldProps } from '@components/CustomTextField/types';

export const StyledPasswordTextField = styled(TextField)<StyledTextFieldProps>`
  position: relative;
  width: 100%;

  /* to reset browser styles for autofill options */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${COLORS.white} inset !important;
  } 
  & .MuiFormHelperText-root {
    position: relative;
    bottom: -3px;
    margin-top: 0;
    letter-spacing: 0.1px;
  }

  & .MuiIconButton-edgeEnd {
    margin-right: -6px;
    padding: 5px;
  }
  
  ${({ $showError }) => {
    if($showError) {
      return css`
        padding-bottom: 20px;

        & .MuiFormHelperText-root {
          width: 100%;
          height: auto !important;
        }
    `;
    } else {
      return css`
        & .MuiFormHelperText-root {
          display: none;
        }
    `;
    }
  }};
`;