import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useToggle } from '@customHooks';
import { useStore } from '@store';

import { AppPage } from '@modules/AppPage';
import Surface from '@components/BasicLayoutComponents/Surface';
import Preloader from '@components/Preloader';

import { AccountAndPlansTable, Filters, PageHeader } from './components';

export const AccountsAndPlans = observer(() => {
  const accountsAndPlansStore = useStore().AccountsAndPlansStore;
  const { isLoad } = accountsAndPlansStore;

  const [ isFiltersOpen, toggleFiltersOpen ] = useToggle();

  useEffect(() => {
    accountsAndPlansStore.initPage();
    return () => accountsAndPlansStore.resetState();
  }, [ accountsAndPlansStore ]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppPageTitle title={ 'Accounts & Plans' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isLoad && <Preloader/>
        }
        <PageHeader
          isFiltersBtnActive={ isFiltersOpen }
          onFiltersClick={ toggleFiltersOpen }
        />
        <Surface.Content $isWrap={ false } $isRightSidePanel>
          <Surface.Main>
            <AccountAndPlansTable/>
          </Surface.Main>
          <Surface.Side isFiltersWithButtonOpen={ isFiltersOpen }>
            <Filters/>
          </Surface.Side>
        </Surface.Content>
      </Surface>
    </AppPage>
  );
});
