import { convertUTCFormatToString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { CONTACT_TYPES } from '@constants/common';
import { LINKED_ITEM_DATA_NAMES, LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';
import { TRASH_TO_DOS_LINKED_ITEMS_TYPES_SCHEME } from '@services/store/trashStore/data';

import { TrashFiltersNames } from '@pages/Trash/types';
import {
  AccountsAndPlans,
  AccountsAndPlansNormalizerItem,
  Activity,
  ActivityNormalizerItem,
  ClientOpportunities,
  ClientOpportunitiesNormalizerItem,
  ContactNormalizerItem,
  Contacts,
  FilterData,
  FilterDataConverter,
  Lists,
  ListsNormalizerItem,
  SalesCycles,
  SalesCyclesNormalizerItem,
  SchemeDataName,
  Tasks,
  TasksNormalizerItem,
  TrashAccountsAndPlans,
  TrashActivities,
  TrashClientOpportunities,
  TrashContacts,
  TrashItem,
  TrashLists,
  TrashNormalizer,
  TrashNormalizerResponse,
  TrashResponseData,
  TrashSalesCycles,
  TrashTasks,
} from '@services/store/trashStore/types';
import { PaginationData } from '@/shared/types/commonTypes';
import { Parent } from '@/shared/types/lists';


const contactItemConverter = (item: Contacts): ContactNormalizerItem => (
  {
    item: {
      id: item.id,
      firstName: item?.firstName,
      lastName: item?.lastName,
      occupationCompany: item?.occupationCompany,
      type: item.type,
      photo: item.photo,
      classification: item.classification as CONTACT_TYPES,
      ...(
        item?.householderContacts
          ? { householderContacts: item?.householderContacts }
          : {}
      )
    },
    ...commonItemProps(item),
  }
);

const activityItemConverter = (item: Activity): ActivityNormalizerItem => (
  {
    item: {
      id: item.id,
      contact: item.contact,
      name: item.name,
      type: item.type,
    },
    ...commonItemProps(item),
  }
);

const accountsAndPlansItemConverter = (item: AccountsAndPlans): AccountsAndPlansNormalizerItem => (
  {
    item: {
      id: item.id,
      contact: item.contact,
      name: item.name,
      type: item.accountType,
    },
    ...commonItemProps(item),
  }
);

const clientOpportunitiesItemConverter = (item: ClientOpportunities): ClientOpportunitiesNormalizerItem => (
  {
    item: {
      id: item.id,
      contactData: item.contactData,
      opportunityStatus: item.opportunityStatus,
      amount: item.amount,
      saleType: item.saleType,
    },
    ...commonItemProps(item),
  }
);

const tasksItemConverter = (item: Tasks): TasksNormalizerItem => {
  const todoConverter = Object.entries(item).reduce((acc, [name, value]) => {
    const isLinkedContact = Object.values(LINKED_ITEM_DATA_NAMES).some((linkedItemName) => {
      return linkedItemName === name;
    });
  
    if(isLinkedContact && value) {
      const isContact = name === LINKED_ITEM_DATA_NAMES.CONTACT;
      //@ts-ignore
      const itemData = isContact ? value : value.contactData;
      const itemType = isContact ? LINKED_ITEM_TYPES.contact :
        TRASH_TO_DOS_LINKED_ITEMS_TYPES_SCHEME[name as SchemeDataName].type;

      acc[TO_DOS_FIELDS_NAMES.linkedItemData] = {
        ...itemData,
        linkedContactType: itemType
      };
      return acc;
    }

    acc[name] = value;
    return acc;
  }, {} as Record<string, unknown>) as TasksNormalizerItem['item'];
  
  return {
    item: {
      id: item.id,
      linkedItemData: todoConverter.linkedItemData,
      name: item.name,
      type: item.category ?? item.request,
    },
    ...commonItemProps(item),
  };
};

const salesCyclesItemConverter = (item: SalesCycles): SalesCyclesNormalizerItem => (
  {
    item: {
      id: item.id,
      contactData: item.contactData,
      salesCycleStatus: item.salesCycleStatus,
      stage: item.stage,
      amount: item.amount,
    },
    ...commonItemProps(item),
  }
);

const listsItemConverter = (item: Lists): ListsNormalizerItem => (
  {
    item: {
      id: item.id,
      contactData: item.contactData,
      name: item.name,
      type: item.typeList,
    },
    ...commonItemProps(item),
  }
);

const commonItemProps = (item: TrashItem) => {
  const arraysOfDeletedDate = item.createdAt.split(' ');
  const { date, time } = convertUTCFormatToString(arraysOfDeletedDate[0], arraysOfDeletedDate[1]);

  return {
    deleteAt: `${date} ${time}`,
    deleteBy: `${ item?.clientFirstName } ${ item?.clientLastName }`,
    editAllow: item.editAllow,
    id: item.id
  };
};

const contactFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.Classification]: optionsFromValue(filterData.classification ?? []),
    [TrashFiltersNames.Users]: getSortedUserOption(filterData.users, currentUserId),
  };
};

const listFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.List_Type]: optionsFromValue(filterData.typeList ?? []),
    [TrashFiltersNames.Users]: getSortedUserOption(filterData.users, currentUserId),
  };
};

const salesCyclesFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.Users]: getSortedUserOption(filterData.clients || [], currentUserId),
    [TrashFiltersNames.Meeting_Status]: optionsFromValue(filterData.meetingStatus ?? []),
    [TrashFiltersNames.Sales_Cycle_Status]: optionsFromValue(filterData.salesCycleStatus ?? []),
  };
};

const clientOpportunitiesFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.Users]: getSortedUserOption(filterData.createdBy || [], currentUserId),
    [TrashFiltersNames.Sale_Type]: optionsFromValue(filterData.saleType ?? []),
    [TrashFiltersNames.Opportunity_Status]: optionsFromValue(filterData.opportunityStatus ?? []),
    [TrashFiltersNames.Meeting_Status]: optionsFromValue(filterData.meetingStatus ?? []),
  };
};

const activityFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.Clients]: getSortedUserOption(filterData.clients || [], currentUserId),
    [TrashFiltersNames.Type]: optionsFromValue(filterData.type ?? []),
  };
};

const taskFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.Clients]: getSortedUserOption(filterData.clients || [], currentUserId),
    [TrashFiltersNames.Type]: optionsFromValue(filterData.type ?? []),
  };
};

const commonFilterDataConverter = (
  filterData: FilterData, currentUserId: number
): FilterDataConverter => {
  if (!filterData) {
    return {} as FilterDataConverter;
  }

  return {
    [TrashFiltersNames.Type]: optionsFromValue(filterData.type ?? []),
    [TrashFiltersNames.Users]: getSortedUserOption(filterData.users, currentUserId),
  };
};

export const itemsForContactTrashNormalizer = (
  response: TrashContacts,
  currentUserId: number
): TrashNormalizer<ContactNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<ContactNormalizerItem>;
  }

  const trashContacts = response.data;
  const {
    filterData,
    items
  } = itemsForTrashNormalizer<Contacts, FilterData>(trashContacts.data);

  return {
    filtersData: contactFilterDataConverter(filterData, currentUserId),
    items: items.map(contactItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForActivityTrashNormalizer = (
  response: TrashActivities,
  currentUserId: number,
): TrashNormalizer<ActivityNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<ActivityNormalizerItem>;
  }

  const trashActivities = response.data;
  const {
    filterData,
    items
  } = itemsForTrashNormalizer<Activity, FilterData>(trashActivities.data);

  return {
    filtersData: activityFilterDataConverter(filterData, currentUserId),
    items: items.map(activityItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForAccountsAndPlansTrashNormalizer = (
  response: TrashAccountsAndPlans,
  currentUserId: number,
): TrashNormalizer<AccountsAndPlansNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<AccountsAndPlansNormalizerItem>;
  }

  const trashAccountsAndPlans = response.data;
  const {
    filterData,
    items
  } = itemsForTrashNormalizer<AccountsAndPlans, FilterData>(trashAccountsAndPlans.data);

  return {
    filtersData: commonFilterDataConverter(filterData, currentUserId),
    items: items.map(accountsAndPlansItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForClientOpportunitiesTrashNormalizer = (
  response: TrashClientOpportunities,
  currentUserId: number,
): TrashNormalizer<ClientOpportunitiesNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<ClientOpportunitiesNormalizerItem>;
  }

  const trashClientOpportunities = response.data;
  const {
    filterData,
    items
  } = itemsForTrashNormalizer<ClientOpportunities, FilterData>(trashClientOpportunities.data);

  return {
    filtersData: clientOpportunitiesFilterDataConverter(filterData, currentUserId),
    items: items.map(clientOpportunitiesItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForTasksTrashNormalizer = (
  response: TrashTasks,
  currentUserId: number,
): TrashNormalizer<TasksNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<TasksNormalizerItem>;
  }

  const trashTasks = response.data;
  const {
    filterData,
    items
  } = itemsForTrashNormalizer<Tasks, FilterData>(trashTasks.data);

  return {
    filtersData: taskFilterDataConverter(filterData, currentUserId),
    items: items.map(tasksItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForSalesCyclesTrashNormalizer = (
  response: TrashSalesCycles,
  currentUserId: number,
): TrashNormalizer<SalesCyclesNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<SalesCyclesNormalizerItem>;
  }

  const trashSalesCycles = response.data;
  const {
    filterData,
    items
  } = itemsForTrashNormalizer<SalesCycles, FilterData>(trashSalesCycles.data);

  return {
    filtersData: salesCyclesFilterDataConverter(filterData, currentUserId),
    items: items.map(salesCyclesItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForListsTrashNormalizer = (
  response: TrashLists,
  currentUserId: number,
): TrashNormalizer<ListsNormalizerItem> => {
  if (!response) {
    return {} as TrashNormalizer<ListsNormalizerItem>;
  }

  const trashLists = response.data;
  const {
    filterData,
    items
  } = itemsForListTrashNormalizer<Lists, FilterData>(trashLists.data);
  
  return {
    filtersData: listFilterDataConverter(filterData, currentUserId),
    items: items.map(listsItemConverter),
    ...getCommonTrashNormalizerData(response)
  };
};

export const itemsForListTrashNormalizer = <Item, Filter>(
  response: {
    filterData: Filter,
    parent: Parent,
  }
): TrashNormalizerResponse<Item, Filter> => {
  const { filterData, parent, ...trashItems } = response;

  const items = Object.values(trashItems) as Array<Item>;

  return {
    items,
    filterData,
  };
};

export const itemsForTrashNormalizer = <Item, Filter>(
  response: {
    filterData: Filter,
  }
): TrashNormalizerResponse<Item, Filter> => {
  const { filterData, ...trashItems } = response;

  const items = Object.values(trashItems) as Array<Item>;

  return { items, filterData };
};

const getCommonTrashNormalizerData = (
  response: TrashResponseData,
): PaginationData => {
  return {
    from: response.data.from,
    current_page: response.data.current_page,
    last_page: response.data.last_page,
    per_page: response.data.per_page,
    to: response.data.to,
    total: response.data.total
  };
};
