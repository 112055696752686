import React, { useCallback } from 'react';

import { useToggle } from '@/shared/customHooks';

import { Drawer } from '@/assets';

import { DrawerWrapper, Wrapper, FilterWrapper } from './styles';

import { SideWrapperProps } from './types';

export const SideWrapper = ({
  children,
  isFiltersPanel = true,
  isFiltersWithButtonOpen,
}: SideWrapperProps) => {

  const [ isFiltersRendered, setIsFiltersRendered ] = useToggle(false);

  const onMouseEnter = useCallback(() => {
    if(!isFiltersRendered && !isFiltersWithButtonOpen) {
      setIsFiltersRendered();
    }
  }, [setIsFiltersRendered, isFiltersWithButtonOpen, isFiltersRendered]);

  const onMouseLeave = useCallback(() => {
    if(isFiltersRendered && !isFiltersWithButtonOpen) {
      setIsFiltersRendered();
    }
  }, [setIsFiltersRendered, isFiltersWithButtonOpen, isFiltersRendered]);

  return(
    <Wrapper onMouseLeave={ onMouseLeave }>
      { isFiltersPanel && 
        <>
          <DrawerWrapper>
            <Drawer onMouseEnter={ onMouseEnter }/>
          </DrawerWrapper>
          { ((isFiltersRendered && !isFiltersWithButtonOpen) || (!isFiltersRendered && isFiltersWithButtonOpen)) && 
            <FilterWrapper>
              { children }
            </FilterWrapper>
          }
        </>
      }

      { !isFiltersPanel && 
        <FilterWrapper>
          { children }
        </FilterWrapper>
      }
    </Wrapper>
  );
};
