import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { observer } from 'mobx-react';
import { useStore } from '@store';

import { AppPage } from '@modules/AppPage';
import DeletePopup from './components/DeletePopup';
import Preloader from '@components/Preloader';
import RestorePopup from './components/RestorePopup';
import Surface from '@components/BasicLayoutComponents/Surface';
import { Filters, PageHeader, TrashTable } from './components';

import { ROUTES } from '@constants/routes';

import { MODAL_TYPE } from '@/shared/constants/modalTypes';
import { IdType } from '@/shared/types/commonTypes';

export const Trash = observer(() => {
  const trashStore = useStore().TrashStore;
  const modalStore = useStore().ModalStore;

  const { isPageLoads, isFiltersOpen } = trashStore;

  const deleteHandler = (arrayOfIds?: Array<IdType>) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_CONTACTS_POPUP,
      modalProps: {
        arrayOfIds
      },
      component: DeletePopup,
    });
  };
  
  const restoreHandler = (arrayOfIds?: Array<IdType>) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONFIRM_CANCEL_MODAL,
      modalProps: {
        arrayOfIds
      },
      component: RestorePopup,
    });
  };

  useEffect(() => {
    trashStore.initPage();
    return () => trashStore.resetState();
  }, [ trashStore ]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppPageTitle title={ 'Trash' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isPageLoads && <Preloader/>
        }
        <PageHeader
          isFiltersBtnActive={ isFiltersOpen }
          onFiltersClick={ trashStore.toggleFiltersOpen.bind(trashStore) }
        />

        <Surface.Content $isWrap={ false } $isRightSidePanel>
          <Surface.Main>
            <Switch>
              <Route path={ ROUTES.trashContacts }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
              <Route path={ ROUTES.trashLists }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
              <Route path={ ROUTES.trashActivities }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
              <Route path={ ROUTES.trashTodos }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
              <Route path={ ROUTES.trashSalesCycles }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
              <Route path={ ROUTES.trashClientOpportunities }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
              <Route path={ ROUTES.trashAccountsAndPlans }>
                <TrashTable 
                  onDeleteHandler={ deleteHandler }
                  onRestoreHandler={ restoreHandler }
                />
              </Route>
            </Switch>
          </Surface.Main>
          <Surface.Side isFiltersWithButtonOpen={ isFiltersOpen }>
            <Filters/>
          </Surface.Side>
        </Surface.Content>
      </Surface>
    </AppPage>
  );
});
