import { CustomFieldTagItem } from '@/shared/types/tags';

export const checkInclusion = (obj: CustomFieldTagItem, arr: Array<CustomFieldTagItem>) => {
  return arr.some((item) => item.id === obj.id);
};

export const excludeOption = (option: CustomFieldTagItem, arr: Array<CustomFieldTagItem>) => {
  if('frontId' in option && option.frontId) {
    //@ts-ignore
    return arr.filter(item => item.frontId !== option.frontId);
  } else {
    return arr.filter(item => item.id !== option.id);    
  }
};
