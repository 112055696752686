import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';

import { AppPage } from '@modules/AppPage';
import {
  ClientSpecificTasks,
  OtherTasks,
  PageHeader,
} from './components';
import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';

import { CLIENT_SPECIFIC_TASKS_PATH, OTHER_TASKS_PATH } from '@constants/routes/toDos';

export const NewToDos = observer(() => {
  const { isTodoLoads, setCurrentTab, resetStore } = useStore().ToDosStore;

  const { params: { tab } } = useRouteMatch<{ 'tab': string }>();

  useEffect(() => {
    setCurrentTab(tab);
  }, [setCurrentTab, tab]);

  useEffect(() => {
    return resetStore;
  }, [resetStore]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppPageTitle title={ 'To-Dos' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isTodoLoads && <Preloader />
        }
        <PageHeader/>
        <Switch>
          <Route path={ CLIENT_SPECIFIC_TASKS_PATH }>
            <ClientSpecificTasks/>
          </Route>
          <Route path={ OTHER_TASKS_PATH }>
            <OtherTasks/>
          </Route>
        </Switch>
      </Surface>
    </AppPage>
  );
});
