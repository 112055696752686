import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { ButtonsWrapperProps } from './types';

export const VIEW_ALL_BUTTON_STYLES = {
  padding: 0,
  marginLeft: 'auto',
  height: 24
};

export const TAG_BUTTON_STYLES = {
  maxWidth: '520px'
};

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
	display: flex;
	align-items: center;
	width: 35px;
	padding: 10px 0;
	margin-bottom: 20px;
	border-bottom: 1px solid ${({ isOpened }) => isOpened ? COLORS.orange : COLORS.borders };
`;

export const ViewAllButton = styled.button`
	display: flex;
	width: 35px;
	margin: 10px 5px 15px 0px;
`;

export const TagsList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
`;

export const AdminTagsList = styled(TagsList)`
	margin-bottom: 20px;
`;
