import React, { useMemo } from 'react';

import Tooltip from '@components/Tooltip';

import { USER_ICON_SIZES } from './data';

import {
  Wrapper,
  StyledImage,
  StyledAbbr,
} from './styles';

import { UserIconProps } from './types';

export const UserIcon = ({
  $color,
  $size = USER_ICON_SIZES.medium,
  $withOutline = false,
  abbr,
  fullName,
  imgSrc
}: UserIconProps) => {

  const content = useMemo(() => {
    if(imgSrc) {
      return <StyledImage src={ imgSrc }/>;
    }
    if(abbr && !imgSrc) {
      return <StyledAbbr $color={ $color } >{abbr}</StyledAbbr>;
    }
    return '';
  }, [imgSrc, abbr]);

  return (
    <Wrapper
      $size={ $size }
      $withOutline={ $withOutline }
    >
      { fullName ?
        <Tooltip title={ fullName }>
          { content }
        </Tooltip> :
        content
      }
    </Wrapper>
  );
};
