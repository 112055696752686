import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';
import { AxiosError, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import {
  getFilterParams,
  getFiltersCount,
  getMultipleSortParams,
  getSortParams
} from '@/shared/utils/filterUtils';
import { getDeleteOrRestoreItemsParams } from '@/shared/utils/getDeleteOrRestoreItemsParams';
import { convertArrayOfStringToHtmlLines } from '@/shared/utils/NotificationHelper/generateMessage';
import { filterItemsByPermission } from '@/shared/utils/filterItemsByPermission';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';

import {
  deleteAccountAndPlansFromTrash,
  deleteActivitiesFromTrash,
  deleteClientOpportunitiesFromTrash,
  deleteContactsFromTrash,
  deleteListsFromTrash,
  deleteSalesCyclesFromTrash,
  deleteTasksFromTrash,
  getAccountsAndPlansForTrash,
  getActivitiesForTrash,
  getClientOpportunitiesForTrash,
  getContactsForTrash,
  getListsForTrash,
  getSalesCyclesForTrash,
  getTasksForTrash,
  restoreAccountAndPlansFromTrash,
  restoreActivitiesFromTrash,
  restoreClientOpportunitiesFromTrash,
  restoreContactsFromTrash,
  restoreListsFromTrash,
  restoreSalesCyclesFromTrash,
  restoreTasksFromTrash,
} from '@services/api/trash/trash';

import {
  itemsForAccountsAndPlansTrashNormalizer,
  itemsForActivityTrashNormalizer,
  itemsForClientOpportunitiesTrashNormalizer,
  itemsForContactTrashNormalizer,
  itemsForListsTrashNormalizer,
  itemsForSalesCyclesTrashNormalizer,
  itemsForTasksTrashNormalizer,
} from '@services/store/trashStore/normalizers/normalizers';

import { Store } from '../store';
import CommonTableStore from '@services/store/commonTableStore';

import { ENTITY_NAMES, SortDirectionNames } from '@constants/common';
import { INIT_NUMBER_OF_DAYS_TO_STORE_DATA } from './data';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { TRASH_TABLE_COLUMNS_IDS } from '@pages/Trash/components/TrashTable/data';

import { TrashTabValues } from '@pages/Trash/types';
import {
  ConfigSettings,
  DateFilters,
  FilterDataConverter,
  Filters,
  NormalizerItem,
  TrashAccountsAndPlans,
  TrashActivities,
  TrashClientOpportunities,
  TrashContacts,
  TrashLists,
  TrashNormalizer,
  TrashSalesCycles,
  TrashTasks,
} from './types';
import { IdType, Items } from '@/shared/types/commonTypes';


class TrashStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  trashStorageDays: number = 185;
  coreStore: Store;
  currentTrashView = TrashTabValues.Contacts;
  dateFilters: DateFilters = {} as DateFilters;
  filters: Filters = {} as Filters;
  filtersData: FilterDataConverter = {} as FilterDataConverter;
  isFiltersOpen: boolean = false;
  isPageActive: boolean = false;
  isPageLoads: boolean = false;
  notificationHelper: NotificationHelper;
  onDateFilterChangeDisposer: IReactionDisposer;
  onFiltersChangeDisposer: IReactionDisposer;
  onViewChangeDisposer: IReactionDisposer;
  table: CommonTableStore<NormalizerItem>;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      deleteItems: flow.bound,
      getTrashAccountsAndPlans: flow.bound,
      getTrashActivities: flow.bound,
      getTrashClientOpportunities: flow.bound,
      getTrashContacts: flow.bound,
      getTrashLists: flow.bound,
      getTrashSalesCycles: flow.bound,
      getTrashTasks: flow.bound,
      makeApiCall: action.bound,
      restoreItems: flow.bound,
    });
    this.coreStore = coreStore;

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.contact
    );

    this.table = new CommonTableStore<NormalizerItem>({
      checkboxItemsProcessor: filterItemsByPermission,
      onPageChangeReactionCallback: this.makeApiCall,
      onSortReactionCallback: this.makeApiCall
    });

    this.onFiltersChangeDisposer = this.createOnFiltersChangeReaction();
    this.onDateFilterChangeDisposer = this.createOnDateFilterChangeReaction();
    this.onViewChangeDisposer = this.createOnViewChangeReaction();
  }

  makeApiCall() {
    if (this.isPageActive) {
      const apiRequest = this.getApi();
      apiRequest();
    }
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive) {
          this.table.currentPage = 1;
          this.makeApiCall();
        }
      }, 1500),
    );
  }

  createOnViewChangeReaction() {
    return reaction(
      () => this.currentTrashView,
      () => {
        if(this.isPageActive) {
          this.onFiltersChangeDisposer();
          this.onDateFilterChangeDisposer();
  
          this.table.resetTable();
          this.resetFilters();
          this.dateFilters = {} as DateFilters;
          this.isFiltersOpen = false;
  
          const apiRequest = this.getApi();
          apiRequest();
  
          this.onFiltersChangeDisposer = this.createOnFiltersChangeReaction();
          this.onDateFilterChangeDisposer = this.createOnDateFilterChangeReaction();
        }
      }
    );
  }

  createOnDateFilterChangeReaction() {
    return reaction(
      () => this.dateFilters,
      () => {
        this.table.currentPage = 1;
        this.makeApiCall();
      }
    );
  }

  *getTrashContacts() : Generator<any, void, any> {
    this.isPageLoads = true;

    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashContacts> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getContactsForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForContactTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTrashLists() : Generator<any, void, any> {
    this.isPageLoads = true;

    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashLists> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getListsForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForListsTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTrashActivities() : Generator<any, void, any> {
    this.isPageLoads = true;

    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashActivities> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getActivitiesForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForActivityTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTrashTasks() : Generator<any, void, any> {
    this.isPageLoads = true;

    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashTasks> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getTasksForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForTasksTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTrashSalesCycles() : Generator<any, void, any> {
    this.isPageLoads = true;
    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashSalesCycles> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getSalesCyclesForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForSalesCyclesTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTrashAccountsAndPlans() : Generator<any, void, any> {
    this.isPageLoads = true;
    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashAccountsAndPlans> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getAccountsAndPlansForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForAccountsAndPlansTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getTrashClientOpportunities() : Generator<any, void, any> {
    this.isPageLoads = true;
    try {
      const createdAtSort = isEmpty(this.table.multipleSorting) &&
      getSortParams(TRASH_TABLE_COLUMNS_IDS.createdAt, SortDirectionNames.Asc);

      const response: AxiosResponse<TrashClientOpportunities> = yield this.asyncRequestExecutor
        .wrapAsyncOperation({
          func: () => (
            getClientOpportunitiesForTrash({
              page: this.table.currentPage,
              ...getFilterParams(this.filters),
              ...getFilterParams(this.dateFilters),
              ...createdAtSort,
              ...getMultipleSortParams(this.table.multipleSorting)
            })
          ),
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => {
            const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
            const normalized = itemsForClientOpportunitiesTrashNormalizer(response?.data, currentUserId);
            this.setNormalizedData(normalized);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  private getEntityName(): string{
    if(this.currentTrashView === TrashTabValues.Contacts){
      return ENTITY_NAMES.contact;
    }
    if(this.currentTrashView === TrashTabValues.Tasks){
      return ENTITY_NAMES.task;
    }
    if(this.currentTrashView === TrashTabValues.Activities){
      return ENTITY_NAMES.activity;
    }
    if(this.currentTrashView === TrashTabValues.AccountsAndPlans){
      return ENTITY_NAMES.accountAndPlans;
    }
    if(this.currentTrashView === TrashTabValues.SalesCycles){
      return ENTITY_NAMES.salesCycle;
    }
    if(this.currentTrashView === TrashTabValues.Lists){
      return ENTITY_NAMES.list;
    }
    if(this.currentTrashView === TrashTabValues.ClientOpportunities){
      return ENTITY_NAMES.clientOpportunity;
    }
    return '';
  }

  *deleteItems(arrayOfIds: Array<IdType>) : Generator<any, void, any> {
    this.isPageLoads = true;
    try {
      const dataToDelete = arrayOfIds ? arrayOfIds : this.table.selectedIDs;

      const params = getDeleteOrRestoreItemsParams(dataToDelete);

      const otherEntityName = this.getEntityName();
      const countOfEntities = dataToDelete.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => this.getDeleteApi(params),
        onError: () => this.notificationHelper.delete({
          status: NOTIFICATION_TYPES.error,
          countOfEntities,
          otherEntityName,
        }),
        onSuccess: () => this.notificationHelper.delete({
          status: NOTIFICATION_TYPES.success,
          countOfEntities,
          otherEntityName,
        })
      });

      const request = this.getApi();
      yield request();

      this.table.checkAndSetIfPageOutOfRange();
      this.table.refreshSelectedIds(dataToDelete);
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *restoreItems(arrayOfIds?: Array<IdType>) : Generator<any, void, any> {
    this.isPageLoads = true;
    try {
      const dataToRestore = arrayOfIds ? arrayOfIds : this.table.selectedIDs;

      const params = getDeleteOrRestoreItemsParams(dataToRestore);

      const otherEntityName = this.getEntityName();
      const countOfEntities = dataToRestore.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => this.getRestoreApi(params),
        onError: (error: AxiosError<unknown, any>) => {
          if(error?.response?.status === 400) {
            this.notificationHelper.customNotification({
              status: NOTIFICATION_TYPES.error,
              //@ts-ignore
              message: convertArrayOfStringToHtmlLines([
                `The ${otherEntityName}${countOfEntities > 1 ? '(s)' : ''} 
                is not restored as the Linked Contact was deleted.`,
              ]),
              customAction: `restore${otherEntityName}`
            });
          } else {
            this.notificationHelper.restore({
              status: NOTIFICATION_TYPES.error,
              countOfEntities,
              otherEntityName,
            });
          }
        },
        onSuccess: () => this.notificationHelper.restore({
          status: NOTIFICATION_TYPES.success,
          countOfEntities,
          otherEntityName,
        })
      });

      const request = this.getApi();
      yield request();

      this.table.checkAndSetIfPageOutOfRange();
      this.table.refreshSelectedIds(dataToRestore);
    } catch (error) {
      console.log(error);
    } finally {
      this.isPageLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  getApi(): () => void {
    switch (this.currentTrashView) {
    case TrashTabValues.Contacts:
      return this.getTrashContacts;
    case TrashTabValues.Lists:
      return this.getTrashLists;
    case TrashTabValues.Activities:
      return this.getTrashActivities;
    case TrashTabValues.Tasks:
      return this.getTrashTasks;
    case TrashTabValues.SalesCycles:
      return this.getTrashSalesCycles;
    case TrashTabValues.AccountsAndPlans:
      return this.getTrashAccountsAndPlans;
    case TrashTabValues.ClientOpportunities:
      return this.getTrashClientOpportunities;
    default: {
      const unexpected: never = this.currentTrashView;
      throw new TypeError(`Unexpected: ${unexpected}`);
    }
    }
  }

  getDeleteApi(params: Items) {
    switch (this.currentTrashView) {
    case TrashTabValues.Contacts:
      return deleteContactsFromTrash(params);
    case TrashTabValues.Lists:
      return deleteListsFromTrash(params);
    case TrashTabValues.Activities:
      return deleteActivitiesFromTrash(params);
    case TrashTabValues.AccountsAndPlans:
      return deleteAccountAndPlansFromTrash(params);
    case TrashTabValues.Tasks:
      return deleteTasksFromTrash(params);
    case TrashTabValues.SalesCycles:
      return deleteSalesCyclesFromTrash(params);
    case TrashTabValues.ClientOpportunities:
      return deleteClientOpportunitiesFromTrash(params);
    default: {
      const unexpected: never = this.currentTrashView;
      throw new TypeError(`Unexpected: ${unexpected}`);
    }
    }
  }

  getRestoreApi(params: Items) {
    switch (this.currentTrashView) {
    case TrashTabValues.Contacts:
      return restoreContactsFromTrash(params);
    case TrashTabValues.Lists:
      return restoreListsFromTrash(params);
    case TrashTabValues.Activities:
      return restoreActivitiesFromTrash(params);
    case TrashTabValues.AccountsAndPlans:
      return restoreAccountAndPlansFromTrash(params);
    case TrashTabValues.Tasks:
      return restoreTasksFromTrash(params);
    case TrashTabValues.SalesCycles:
      return restoreSalesCyclesFromTrash(params);
    case TrashTabValues.ClientOpportunities:
      return restoreClientOpportunitiesFromTrash(params);
    default: {
      const unexpected: never = this.currentTrashView;
      throw new TypeError(`Unexpected: ${unexpected}`);
    }
    }
  }

  initPage() {
    this.isPageActive = true;
    const apiRequest = this.getApi();
    apiRequest();
  }

  setNormalizedData(normalized: TrashNormalizer<NormalizerItem>) {
    this.filtersData = normalized?.filtersData;

    this.table.items = normalized.items;
    this.table.setPaginationData(normalized);
  }

  setTrashStorageDays(days: number) {
    this.trashStorageDays = days;
  }

  setView(view: TrashTabValues) {
    this.currentTrashView = view;
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  resetFilters() {
    this.filters = {} as Filters;
  }

  get selectedFiltersCount() {
    return getFiltersCount(this.filters);
  }

  resetState() {
    this.onFiltersChangeDisposer();
    this.onDateFilterChangeDisposer();

    this.isPageActive = false;
    this.dateFilters = {} as DateFilters;
    this.resetFilters();
    this.isFiltersOpen = false;
    this.table.resetTable();

    this.onFiltersChangeDisposer = this.createOnFiltersChangeReaction();
    this.onDateFilterChangeDisposer = this.createOnDateFilterChangeReaction();
  }

  setDateFilters(newDateFilters: DateFilters) {
    this.dateFilters = newDateFilters;
  }

  toggleFiltersOpen() {
    this.isFiltersOpen = !this.isFiltersOpen;
  }
}

export default TrashStore;
