
import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddContactPopupStore } from '../../addContactStore';

import { TabBar } from '@modules/AddContactModal/components';
import FormConstructor from '../../components/FormBlocks/FormConstructor';
import ModalFooter from '../../components/ModalFooter';
import Preloader from '@components/Preloader';

import ModalPortal from '@modules/Modals/components/Modal/ModalPortal';

import { Stepper } from '@components/Stepper';

import { MODAL_TYPE as modalTypes } from '@constants/modalTypes';

import { useStore } from '@store';

import { Container, Content, DisableOverlay, LoaderWrapper, StepperWrapper } from './styles';

function ModalContent() {
  const store = useAddContactPopupStore();
  const modalStore = useStore().ModalStore;

  const methods = useForm({
    resolver: yupResolver(store.validationSchema),
  });

  const {
    formState,
    getValues,
    handleSubmit,
    reset,
    trigger
  } = methods;

  const [ actionTypeToSwitch, setActionTypeToSwitch ] = useState(null);
  const [ currentType, setCurrentType ] = useState(null);

  const submitHandler = (data) => {
    if (!data[store.selectedStep]) {
      const errorStep = store.validateForm(getValues());
      if (errorStep) {
        stepChangeHandler(errorStep);
        handleSubmit(null, submitHandler)();
      } else {
        store.saveContact();
      }
    }
  };

  const stepChangeHandler = (step) => {
    store.setSelectedStep(step, getValues());
  };

  const closeModalHandler = () => {
    modalStore.closeModal(modalTypes.ADD_CONTACT_MODAL);
  };

  useEffect(() => {
    store.getContactOptions();
    reset(store.formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.selectedType]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.selectedType]);

  useEffect(() => {
    store.getContactTags();
    return () => store.resetFormValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTab = useCallback((newType) => {
    if(formState.isDirty) {
      setActionTypeToSwitch(true);
      setCurrentType(newType);
    } else {
      store.setSelectedType(newType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formState.isDirty]);

  const onAttentionCancelClick = useCallback(() => {
    setActionTypeToSwitch(null);
  }, []);

  const onAttentionSaveClick = useCallback(() => {
    reset(store.formValues);
    setActionTypeToSwitch(false);
    store.setSelectedType(currentType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeToSwitch]);

  useEffect(() => {
    return store.resetStore;
  },[store.resetStore]);

  return (
    <Container>
      {
        store.isFetching &&
        <LoaderWrapper>
          <Preloader isWithoutFullPageWrapper={ true } />
        </LoaderWrapper>
      }
      {
        Boolean(actionTypeToSwitch) && <DisableOverlay />
      }
      <TabBar
        contactTypes={ store.contactTypes }
        onChange={ onChangeTab }
        selectedType={ store.selectedType }
      />
      <StepperWrapper>
        <Stepper
          activeStep={ store.selectedStep }
          onChangeStep={ stepChangeHandler }
          steps={ store.stepData }
        />
      </StepperWrapper>
      <Content>
        <FormProvider { ...methods }>
          <FormConstructor/>
        </FormProvider>
      </Content>
      <ModalPortal>
        <ModalFooter
          cancelHandler={ closeModalHandler  }
          currentStep={ store.selectedStep }
          isAttentionMode={ Boolean(actionTypeToSwitch) }
          isButtonsDisabled={ store.isFetching }
          onAttentionCancelClick={ onAttentionCancelClick }
          onAttentionSaveClick={ onAttentionSaveClick }
          saveHandler={ () => handleSubmit(() => {
            store.updateValues(getValues());
            store.saveContact();
          }, submitHandler)() }
          stepChangeHandler={ stepChangeHandler }
        />
      </ModalPortal>
    </Container>
  );
}

export default observer(ModalContent);
