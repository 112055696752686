import { getCountriesOptions } from '@/shared/utils/getOptionsUtils';
import { getStatesOptions } from '@/shared/utils/getStatesOptions';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { CONTACT_TYPE } from '@modules/AddContactModal/data';

import { CONTACT_PREFERENCE_OPTIONS } from '@constants/contactsData';
import { CONTACT_STATUSES_OPTIONS } from '@constants/common';
import {
  tagsTypes,
  CONTACT_OPTIONS_URL
} from '@modules/AddContactModal/addContactStore/data/constantData';

const advisersConverter = (advisers) => (
  advisers.map(adviser => ({
    firstName: adviser.firstName,
    lastName: adviser.lastName,
    id: adviser.id,
    abbr: adviser.firstName[0] + adviser.lastName[0],
    fullName: `${adviser.firstName} ${adviser.lastName}`,
    photo: adviser.logo,
  }))
);

const sourceConverter = (sources) => (
  sources.map(source => ({
    label: source.option,
    value: source.id
  }))
);

export const getContactRoute = (selectedType) => {
  switch (selectedType) {
  case CONTACT_TYPE.Household:
    return CONTACT_OPTIONS_URL.household;
  case CONTACT_TYPE.Business:
    return CONTACT_OPTIONS_URL.business;
  default:
    return CONTACT_OPTIONS_URL.individual;
  }
};

export const optionsNormilizer = (
  statesOptions,
  countriesOptions,
  options,
  type
) => {
  const commonOptions = {
    countries: getCountriesOptions(countriesOptions),
    classification: optionsFromValue(options.coreClassification),
    preferences: CONTACT_PREFERENCE_OPTIONS,
    primaryAdvisers: advisersConverter(options.adviser),
    ranks: options.rating,
    states: getStatesOptions(statesOptions),
    sourcesCategories: sourceConverter(options.sourceCategory),
    sources: sourceConverter(options.source),
    statuses: CONTACT_STATUSES_OPTIONS
  };

  if (type === CONTACT_TYPE.Individual || type === CONTACT_TYPE.Household) {
    commonOptions.gender = optionsFromValue(options.gender);
  }

  if (type === CONTACT_TYPE.Business) {
    commonOptions.companyTypes = optionsFromValue(options.companyType);
    commonOptions.employeeCount = optionsFromValue(options.employees);
  }



  return commonOptions;
};

export const getErrorStep = (errors) => {
  return parseInt(errors[0].path.slice(0, 1));
};

export const TagsNormilizer = (tags) => {
  const interest = [];
  const general = [];

  tags.forEach((tag) => {
    if (tag.type === tagsTypes.general) {
      return general.push({ label: tag.label, id: tag.id, type: tag.type });
    }
    interest.push({ label: tag.label, id: tag.id });
  });

  return { interest, general };
};

export const getTagsInitState = () => {
  return {
    interest: [],
    general: []
  };
};
