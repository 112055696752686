import styled from 'styled-components';

import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

export const Content = styled.form`
  max-width: 800px;
  padding: 0 30px 12px;
`;

export const Container = styled(MuiBox)`
  max-width: 800px;
  min-width: 800px;
`;

export const StepperWrapper = styled(MuiBox)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100px;
  padding: 0 70px 30px 70px;
  border-top: 1px solid ${COLORS.dividingLines};

  & > div {
    margin-left: 0;
  }

  margin-bottom: 10px;
`;

export const DisableOverlay = styled.div`
  width: 95%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  background-color: ${ COLORS.white };
  opacity: 0.5;
`;

export const LoaderWrapper = styled(MuiBox)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
